import {call} from "./apiCall";

/**
 * Desactivate an agent
 * @param {int} agentId
 * @param {Date} desactivationDate
 */
export const desactivateAgent = async (agentId, desactivationDate) => {
  const desactivateResponse = await call(
    `/v1/admin/agent/${agentId}/desactivate`,
    "POST",
    `desactivationDate=${desactivationDate.format("YYYY-MM-DD HH:mm:ss")}`
  );

  return desactivateResponse;
};

/**
 * Desactivate agents
 * @param {int} agentIds
 * @param {Date} desactivationDate
 */
export const desactivateAgents = async (agentIds, desactivationDate) => {
  const desactivateResponse = await call(
    `/v1/admin/agent/batch_desactivate`,
    "POST",
    `agentIds=${agentIds}&desactivationDate=${desactivationDate.format(
      "YYYY-MM-DD HH:mm:ss"
    )}`
  );

  return desactivateResponse;
};

/**
 * Activate an agent
 * @param {int} agentId
 * @param {Date} activationDate
 * @param {int} deliveryPlaceId
 * @param {int} serviceId
 */
export const activateAgent = async (
  agentId,
  activationDate,
  serviceId,
  deliveryPlaceId,
  wardrobe,
  locker
) => {
  const desactivateResponse = await call(
    `/v1/admin/agent/${agentId}/activate/deliveryPlace/${deliveryPlaceId}/service/${serviceId}`,
    "POST",
    `activationDate=${activationDate.format(
      "YYYY-MM-DD HH:mm:ss"
    )}&wardrobe=${wardrobe}&locker=${locker}`
  );

  return desactivateResponse;
};
