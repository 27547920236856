import React, { useState, useEffect } from 'react';
import { getNotifications, deleteNotification, deleteNotifications } from "../../../api/userNotifications";
import { Spin, List } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const NotificationsList = () => {
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState();

  const fetchNotifications = () => {
    setLoading(true);
    getNotifications().then((result) => {
      const { entities } = result;

      setNotifications(entities);
      setLoading(false);
    });
  };

  const removeAll = () => {
    deleteNotifications().then(() => fetchNotifications());
  };

  const removeNotification = (notificationId) => {
    deleteNotification(notificationId).then(() => fetchNotifications());
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="notifications">
      <div className="notifications__header">
        <h3>Notifications</h3>
        <span onClick={() => removeAll()}>Tout supprimer</span>
      </div>
      {loading ? <Spin /> : (
        <List
          dataSource={notifications}
          locale={{ emptyText: 'Aucune notifications à afficher.' }}
          renderItem={(notification) => (
            <List.Item
              className={notification.state === 1 ? 'notification__wrapper--seen' : 'notification__wrapper--unseen'}
              key={notification.id}
            >
              <span className="notifications__category">Tickets</span>
              {notification.state === 0 && (<span className="notification__dot" />)}
              <a href={notification.target}>
                <List.Item.Meta
                  title={notification.title}
                  description={new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium', timeStyle: 'medium' }).format(Date.parse(notification.createdAt))}
                />
                <div>{notification.message}</div>
              </a>
              <span onClick={() => removeNotification(notification.id)} class="notifications__delete"><DeleteOutlined /></span>
            </List.Item>
          )}
        />
      )}
    </div>
  );
};

export default props => <NotificationsList {...props} />;
