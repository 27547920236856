var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useEffect, useState } from 'react';
import { askReform, getTagReformReasons } from '../../../../api/reform';
import ReformReasonList from './ReformReasonList';
import Spinner from '../../../shared/spinner';
import { AskReformContext } from '../context/AskReformProvider';
import { REFORM_ASKED, SET_CAN_EDIT, SET_IS_BLOCKED_BY_REPAIRS } from "../context/AskReformReducer";
import { ExclamationCircleFilled } from '@ant-design/icons';
var AskReformContent = function (_a) {
    var isOpen = _a.isOpen;
    var _b = useContext(AskReformContext), _c = _b[0], tagData = _c.tagData, canEdit = _c.canEdit, isBlockedByRepairs = _c.isBlockedByRepairs, dispatch = _b[1];
    var _d = useState(null), reformReasons = _d[0], setReformReasons = _d[1];
    var _e = useState([]), selectedReasonsIds = _e[0], setSelectedReasonsIds = _e[1];
    var _f = useState(false), hasLoaded = _f[0], setHasLoaded = _f[1];
    var _g = useState(false), isAskingReform = _g[0], setIsAskingReform = _g[1];
    var _h = useState(null), error = _h[0], setError = _h[1];
    /**
     * Handle edit and existant reasons
     */
    useEffect(function () {
        if (tagData) {
            var isBlockedByRepairs_1 = tagData.repairs.find(function (repair) { return repair.isFromCurrentWashingCycle; }) !== undefined
                || tagData.repairRequests.find(function (repairRequest) { return repairRequest.isFromCurrentWashingCycle; }) !== undefined;
            dispatch({ type: SET_CAN_EDIT, payload: !tagData.waitingReformation && !tagData.isReformed && !isBlockedByRepairs_1 });
            dispatch({ type: SET_IS_BLOCKED_BY_REPAIRS, payload: isBlockedByRepairs_1 });
            if (tagData.reformReasons.length > 0) {
                setSelectedReasonsIds(tagData.reformReasons.map(function (r) { return r.id; }));
            }
        }
    }, [tagData]);
    /**
     * Fetch data on first opening
     */
    useEffect(function () {
        if (isOpen && tagData.id && reformReasons === null) {
            getTagReformReasons(tagData.id)
                .then(function (data) {
                if (!data.success) {
                    throw new Error(data.response);
                }
                setReformReasons(data.response);
            })
                .catch(function (error) { return console.error('An error occurred while fetching reforms reasons.', error); })
                .finally(function () { return setHasLoaded(true); });
        }
    }, [isOpen]);
    /**
     * @param reasonId
     */
    var switchReason = function (reasonId) {
        if (selectedReasonsIds.includes(reasonId)) {
            setSelectedReasonsIds(selectedReasonsIds.filter(function (s) { return s !== reasonId; }));
        }
        else {
            setSelectedReasonsIds(__spreadArray(__spreadArray([], selectedReasonsIds, true), [reasonId], false));
        }
    };
    /**
     * Ask reform and refresh jQuery part
     */
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, e_1, scanInput;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setIsAskingReform(true);
                    if (reformReasons.length > 0 && !selectedReasonsIds.length) {
                        throw new Error('Veuillez sélectionner au moins un motif de réforme.');
                    }
                    return [4 /*yield*/, askReform(tagData.id, selectedReasonsIds)];
                case 1:
                    data = _a.sent();
                    if (!data.success) {
                        throw new Error("Une erreur est survenue : ".concat(data.response));
                    }
                    // Dispatch event to script.js, to refresh view.
                    document.dispatchEvent(new CustomEvent('askReformDone', {
                        detail: { data: data.response }
                    }));
                    dispatch({ type: REFORM_ASKED });
                    setError(null);
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _a.sent();
                    setError(e_1.message);
                    return [3 /*break*/, 4];
                case 3:
                    setIsAskingReform(false);
                    if (!tagData.infraHasReformAuto) {
                        scanInput = document.getElementById('scan-input');
                        scanInput.value = '';
                        scanInput.focus();
                    }
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    if (!isOpen) {
        return null;
    }
    return (React.createElement("div", { className: "ask-reform--content" }, !hasLoaded
        ? React.createElement(Spinner, { classes: "ask-reform--spinner" })
        : (React.createElement(React.Fragment, null,
            React.createElement("h4", null, reformReasons.length > 0
                ? React.createElement("span", { className: 'reform-reasons' },
                    React.createElement("p", null, "Motifs de r\u00E9forme :"),
                    React.createElement("p", { className: 'reform-reasons-required' }, "Obligatoire"),
                    React.createElement(ExclamationCircleFilled, null))
                : 'Aucun motif de réforme disponible'),
            React.createElement("span", null, tagData && tagData.agent && tagData.agent.service.infrastructure.acceptReformAuto === true && (React.createElement("i", null, "Cet \u00E9tablissement client a souscrit \u00E0 la r\u00E9forme automatique."))),
            React.createElement(ReformReasonList, { reasons: reformReasons, selectedReasonsIds: selectedReasonsIds, switchReason: switchReason }),
            canEdit && (React.createElement("button", { id: "ask-reform--form-submit", className: "ui black tiny button ".concat(isAskingReform && 'loading'), onClick: handleSubmit, disabled: reformReasons.length > 0 && !selectedReasonsIds.length }, "Proposer la r\u00E9forme")),
            isBlockedByRepairs && (React.createElement("div", { className: "red", style: { marginTop: '10px' } }, "Des r\u00E9parations emp\u00EAchent la mise en r\u00E9forme.")),
            error && React.createElement("div", { className: "red" }, error)))));
};
export default AskReformContent;
