import React from "react";
import AgentCard from "./agentCard";
import IconTransmission from "../../../svg/transmission";
import Row from "../grid/row";
import Col from "../grid/col";
var TransferScheme = function (_a) {
    var originalAgent = _a.originalAgent, recipientAgent = _a.recipientAgent, transferDotation = _a.transferDotation;
    return (transferDotation && (React.createElement(Row, { className: "rform__row justify-content-center" },
        React.createElement(AgentCard, { agentName: originalAgent }),
        React.createElement(Col, { sm: 3, className: "justify-content-center" },
            React.createElement(IconTransmission, null)),
        React.createElement(AgentCard, { agentName: recipientAgent, reverse: true }))));
};
export default TransferScheme;
