import React from 'react';
import Row from '../../grid/row';
import Col from '../../grid/col';
import i18n from '../../../libs/i18n/i18n';
var DotationInformation = function (_a) {
    var data = _a.data, label = _a.label;
    return (React.createElement(Row, { className: "rform__row" },
        React.createElement(Col, { sm: 3 },
            i18n.t(label),
            ":"),
        React.createElement(Col, { sm: 7, column: true },
            React.createElement("span", { className: "rform__info rform__info--bold" },
                data.clothReference,
                " - ",
                data.agentFirstname,
                " ",
                data.agentName,
                " -",
                " ",
                data.agentRegistrationNumber),
            React.createElement("span", { className: "rform__info" },
                React.createElement("p", null,
                    data.clientName,
                    " - ",
                    data.infrastructure,
                    " - ",
                    data.service,
                    " -",
                    " ",
                    data.deliveryPlace)))));
};
export default DotationInformation;
