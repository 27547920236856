import React from "react";
import i18n from "../../libs/i18n/i18n";
var Button = function (_a) {
    var color = _a.color, tradKey = _a.tradKey, onClick = _a.onClick, _b = _a.show, show = _b === void 0 ? false : _b, _c = _a.rounded, rounded = _c === void 0 ? false : _c;
    if (!show) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: "rbutton ".concat(color ? "rbutton--".concat(color) : "", " ").concat(rounded ? "rbutton--rounded" : ""), onClick: function () { return onClick(); } }, i18n.t(tradKey)));
};
export default Button;
