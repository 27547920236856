import React, {useState} from "react";

import Search from "../../../../svg/search.js";
import i18n from "../../../libs/i18n/i18n";

const InputSearch = ({searchAction, className, placeholder}) => {
  const [search, setSearch] = useState();

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      searchAction(search);
    }
  };

  return (
    <div className={className}>
      <input
        type="search"
        placeholder={placeholder}
        onChange={e => setSearch(e.target.value)}
        onKeyDown={e => handleKeyDown(e)}
      />
      <button onClick={() => searchAction(search)}>
        {i18n.t("search")} <Search fill="#fff" />
      </button>
    </div>
  );
};

export default InputSearch;
