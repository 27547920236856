import i18n from 'i18n-js';

/** Setting default locales */
i18n.defaultLocale = "fr-FR";
i18n.locale = "fr-FR";

/** Setting translations */
i18n.translations["fr-FR"] = require("./translations/fr.json");
i18n.translations["en-GB"] = require("./translations/en.json");

export default i18n;