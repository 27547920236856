import React, { useContext } from 'react';
import Spinner from '../../../shared/spinner';
import { TransferContext } from '../context/TransferProvider';
import { getTags } from '../context/TransferAction';
import useTagsLoading from '../hooks/useTagsLoading';
var TagsTotal = function () {
    var _a = useContext(TransferContext), state = _a[0], dispatch = _a[1];
    var _b = useTagsLoading(), isLoading = _b[0], setIsLoading = _b[1];
    var refresh = function () {
        setIsLoading(true);
        dispatch(getTags());
    };
    if (state.tags.length === 0) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "tags-to-transfer--actions" },
            React.createElement("button", { onClick: refresh, className: "ui button button--blue refresh-button" }, isLoading
                ? React.createElement(Spinner, { classes: "scan-spinner" })
                : React.createElement("span", null,
                    React.createElement("i", { className: "icon refresh" }),
                    " Rafra\u00EEchir")),
            React.createElement("div", { className: "total" },
                "TOTAL : ",
                React.createElement("span", null, state.tags.length)))));
};
export default TagsTotal;
