import { useReducer } from 'react';
/**
 * Middleware to add possibility to dispatch an action.
 *
 * @param reducer
 * @param initialState
 */
var useMiddlewareReducer = function (reducer, initialState) {
    var _a = useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    var dispatchWithMiddleware = function (action) {
        if (typeof action === 'function') {
            action(dispatch, state);
        }
        else {
            dispatch(action);
        }
    };
    return [state, dispatchWithMiddleware];
};
export default useMiddlewareReducer;
