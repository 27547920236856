import React, {useEffect} from "react";

import Container from "../../../grid/container";
import Row from "../../../grid/row";
import Col from "../../../grid/col";

import i18n from "../../../../libs/i18n/i18n";
import Loading from "../../../shared/loading";

import {setAgentDeliveryPlaceAssociation} from "../../../../api/agentMutation";
import {checkClothInCatalogByAgent, checkClothInCatalogByInfrastructure, setTagAgent} from "../../../../api/dotationTransfer";

const StepLoading = ({
  setCurrentStep,
  data,
  dotations,
  setErrorMessage,
  deliveryPlaceSelected,
  selectedAgentId,
  infrastructureSelected,
  transferDotation,
  serviceSelected,
  armoire,
  casier,
  agent,
  multiple,
}) => {
  useEffect(() => {
    const check = async (multiple) => {

      try {
        if (multiple) {
          const agentIds = data.map((encodedAgent) => JSON.parse(encodedAgent).id);

          let errors = "";
          let success = true;
          await Promise.all(
            agentIds.map(async (agentId) => {
              if (selectedAgentId[agentId]) {
                const isInCatalog = await checkClothInCatalogByAgent(
                  selectedAgentId[agentId],
                  dotations[agentId].map(dotation => dotation.clothId)
                );

                if (isInCatalog.success) {
                  await setTagAgent(
                    dotations[agentId].map(dotation => dotation.id),
                    selectedAgentId[agentId]
                  );

                  await setAgentDeliveryPlaceAssociation(
                    agentId,
                    deliveryPlaceSelected,
                    serviceSelected,
                    armoire[agentId],
                    casier[agentId]
                  );
                } else {
                  success = false;
                  const agent = data.map((encodedAgent) => JSON.parse(encodedAgent)).filter((decodedAgent) => decodedAgent.id == agentId);
                  if (isInCatalog.catalogs && isInCatalog.clothsNotFound) {
                    errors += "- Dotation non transférable, proteur " + agent.registrationNumber + 
                      " catalogue " + isInCatalog.catalogs + 
                      ", vêtement(s) non trouvé(s) " + 
                      isInCatalog.clothsNotFound + "." + "\n";
                  } else {
                    errors + "- Porteur non transférable " + agent.registrationNumber + ", ";
                  }
                }
              } else {
                await setAgentDeliveryPlaceAssociation(
                  agentId,
                  deliveryPlaceSelected,
                  serviceSelected,
                  armoire[agentId],
                  casier[agentId]
                );
              }
            })
          );

          if (!success) {
            setErrorMessage(errors);
            setCurrentStep("error");
          } else {
            setCurrentStep("success");
          }
        } else {
          if (transferDotation) {
            const isInCatalog = await checkClothInCatalogByAgent(
              selectedAgentId,
              dotations.map(dotation => dotation.clothId)
            );

            if (isInCatalog.success) {
              await setTagAgent(
                dotations.map(dotation => dotation.id),
                selectedAgentId
              );

              await setAgentDeliveryPlaceAssociation(
                data.id,
                deliveryPlaceSelected,
                serviceSelected,
                armoire,
                casier,
              );

              setCurrentStep("success");
            } else {
              setErrorMessage(
                i18n.t("transferError", {
                  clothsNotFound: isInCatalog.clothsNotFound,
                  catalogs: isInCatalog.catalogs,
                  infrastructure: agent.infrastructure.name,
                })
              );
              setCurrentStep("error");
            }
          } else {
            const isInCatalog = await checkClothInCatalogByInfrastructure(
              infrastructureSelected,
              dotations.map(dotation => dotation.clothId)
            );

            if (isInCatalog.success) {
              await setAgentDeliveryPlaceAssociation(
                data.id,
                deliveryPlaceSelected,
                serviceSelected,
                armoire,
                casier
              );
  
              setCurrentStep("success");
            } else {
              setErrorMessage(
                "Les vêtements suivants du porteur ne figurent pas dans le catalogue de destination: " + isInCatalog.clothsNotFound.join(", ")
              );
              setCurrentStep("error");
            }
          }
        }
      } catch (e) {
        console.log("Erreur lors de l'opération: ", e);
        setErrorMessage("Erreur lors de l'opération");
        setCurrentStep("error");
      }
    };

    window.setTimeout(() => check(multiple), 1000);
  }, []);

  return (
    <div className="loading rpoppin__loading">
      <Container>
        <Row className={"justify-content-center"}>
          <Col sm={8}>
            <div className="rpoppin__loading__title">
              Agent(s) à muter:{" "}
              {multiple && data.map((agent) => {
                const agentDecoded = JSON.parse(agent);

                return (
                  <span>
                    {agentDecoded.name} {agentDecoded.firstname} - {agentDecoded.registrationNumber}
                  </span>
                );
              })}

              {!multiple && (
                <span>
                  {data.name} {data.firstname} - {data.registrationNumber}
                </span>
              )}
            </div>
            <p>En cours...</p>
          </Col>
        </Row>
        <Loading />
      </Container>
    </div>
  );
};

export default StepLoading;
