import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { searchAgent } from "../../../api/searchAgent";
import i18n from "../../../libs/i18n/i18n";

const SearchDropdown = ({ id, name, placeholder, required, favoriteWashingCenter }) => {
  const [groupedResults, setGroupedResults] = useState([]);
  const [searchText, setSearchText] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);
  const throttleTimeout = useRef(null);

  const formatGroupLabel = groupedResults => (
    <div className="select--group--label">
      <span>{groupedResults.label}</span>
      <em>{groupedResults.options.length}</em>
    </div>
  );

  // Throttle function to limit the rate of search function calls
  const throttle = (func, delay) => {
    return (...args) => {
      if (throttleTimeout.current) {
        clearTimeout(throttleTimeout.current);
      }
      throttleTimeout.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  useEffect(() => {
    let toPush = [
      {
        label: i18n.t("active_agents"),
        options: [],
      },
      {
        label: i18n.t("inactive_agents"),
        options: [],
      },
    ];

    const search = async () => {
      const results = await searchAgent([searchText]);
      setLoading(false);

      if (results.length) {
        for (let agent of results) {
          agent.isActive ? toPush[0].options.push(agent) : toPush[1].options.push(agent);
        }
      }

      setGroupedResults(toPush);
    };

    const throttledSearch = throttle(() => {
      if (searchText) {
        search();
        setLoading(true);
      } else {
        setGroupedResults([]);
      }
    }, 300); // 300 ms delay

    throttledSearch();

    // Cleanup function to clear the timeout if the component unmounts or if searchText changes
    return () => {
      if (throttleTimeout.current) {
        clearTimeout(throttleTimeout.current);
      }
    };
  }, [searchText]);

  useEffect(() => {
    if (optionSelected) {
      !optionSelected.isActive
        ? document.dispatchEvent(new CustomEvent("inactive_agent_selected"))
        : document.dispatchEvent(new CustomEvent("active_agent_selected"));

      optionSelected.isUnknown || optionSelected.isStock
        ? document.dispatchEvent(new CustomEvent("open_info_previous"))
        : document.dispatchEvent(new CustomEvent("close_info_previous"));
    }
  }, [optionSelected]);

  return (
    <div className="searchdropdown">
      <label htmlFor={id}>
        {i18n.t("agent")}
        {required ? <span className="required"> *</span> : false}
      </label>
      <Select
        options={groupedResults}
        isClearable={true}
        isLoading={isLoading}
        loadingMessage={() => i18n.t("searching_agent")}
        placeholder={i18n.t(placeholder) ? i18n.t(placeholder) : ""}
        formatGroupLabel={formatGroupLabel}
        onInputChange={searchText => setSearchText(searchText)}
        onChange={value => setOptionSelected(value)}
        closeMenuOnScroll={false}
        escapeClearsValue={false}
        noOptionsMessage={() => "Aucun résultat"}
        filterOption={null}
        styles={{
          group: (provided, state) => {
            if (state.headingProps.id === "react-select-2-group-1-heading") {
              return {
                color: "#a6adba"
              };
            }
          },
          groupHeading: (provided) => ({
            ...provided,
            paddingTop: "1rem"
          }),
          option: (provided, state) => {
            let color = "";
            if (state.data.isActive) {
              color = state.data.washingCenter === favoriteWashingCenter ? "#008225" : "#E70808";
            } else {
              color = "#a6adba";
            }
            
            return {
              ...provided,
              color: color
            };
          }
        }}
      />
      <select id={id} name={name}>
        <option value={optionSelected ? optionSelected.value : ""} defaultValue>
          {optionSelected ? optionSelected.value : ""}
        </option>
      </select>

      {optionSelected && !optionSelected.isActive && (
        <div style={{ color: "red" }}>
          Aucun vêtement ne peut être créé pour un porteur désactivé.
        </div>
      )}

      {optionSelected && optionSelected.isActive &&  optionSelected.washingCenter !== favoriteWashingCenter && (
        <div 
          style={{ 
            marginTop: "4px",
            padding: "11px 12px",
            borderRadius: "8px",
            background: 'var(--DARKMODE-Danger, #E82815)',
            color: 'var(--White, var(--DARKMODE-White, #FFF))',
            fontFamily: 'Inter',
            fontWeight: '700',
          }}>
          ATTENTION VOUS CREEZ UNE DOTATION POUR UN PORTEUR GERÉ PAR UNE AUTRE UNITE GEORGES
        </div>
      )}
    </div>
  );
};

export default props => <SearchDropdown {...props} />;
