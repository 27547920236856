import {call} from "./apiCall";

/**
 * Récupère la home d'un client
 * @param {*} userId
 */
export const getMenuByUser = async () => {
  const home = await call(`/v1/admin/menu/get_by_user`, "GET");

  return home;
};
