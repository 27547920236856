import React, {useState, useEffect} from "react";
import {getMenuByUser} from "../../../api/menu";
import Logo from "../../../../svg/Logo_orange_gris_RVB";
import IconAgents from "../../../../svg/IconAgents";
import IconHomepage from "../../../../svg/IconHomepage";

const Menu = () => {
  const [menu, setMenu] = useState(null);

  useEffect(() => {
    const getMenu = async () => {
      let menu = await getMenuByUser();

      setMenu(menu);
    };

    getMenu();
  }, []);

  const icons = color => ({
    logo: <Logo color={color} />,
    agents: <IconAgents color={color} />,
    homepage: <IconHomepage color={color} />,
  });

  const Content = ({menu}) => {
    if (!menu) {
      return <div>Loading</div>;
    }

    return menu.menuItems.map(item => (
      <div className="menuV2__item" key={item.id}>
        {icons("#A6AFBF")[item.icon]}
      </div>
    ));
  };

  return (
    <div className="menuV2">
      {icons("#A6AFBF")["logo"]}
      <Content menu={menu} />
    </div>
  );
};

export default Menu;
