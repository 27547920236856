import React, {useState, useEffect} from "react";
import MainBanner from "./components/mainBanner";
import MainLayout from "../../layout/MainLayout";
import Card from "../../layout/Card";
import BlockInfos from "./components/blockInfos";
import LinkFile from './components/linkFile';

const ExtranetHomeContent = ({user, client}) => {
  console.log(client.home);
  return (
    <MainLayout heading={false}>
      <div className="content content__extranet">
        {console.log(client)}
        <MainBanner
          userName={user.name}
          clientName={client.name}
          templateTitle={client.home.templateTitle}
          templateBody={client.home.templateBody}
        />
        <Card>
          <BlockInfos files={[client.home]} />
          <LinkFile  />
        </Card>
        <Card>
          <BlockInfos />
        </Card>
      </div>
    </MainLayout>
  );
};

export default props => <ExtranetHomeContent {...props} />;
