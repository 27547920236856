import React, {useState, useEffect} from "react";

import StepMain from "./components/stepMain";
import StepMainMultiple from "./components/stepMainMultiple";
import StepLoading from "./components/stepLoading";
import StepResult from "./components/stepResult";
import ModalWrapper from "../../shared/modal/modalWrapper";

import {getAgentDotations} from "../../../api/agentMutation";
import i18n from "../../../libs/i18n/i18n";
import moment from "../../../libs/moment/moment";

const ModuleAgentDesactivation = ({data, multiple}) => {
  const defaultDate = moment();
  const [currentStep, setCurrentStep] = useState(
    multiple && multiple == true ? "start_multiple" : "start"
  );
  const [transferDotation, setTransferDotation] = useState(
    multiple && multiple == true ? [] : false
  );
  const [selectedAgentId, setSelectedAgentId] = useState(
    multiple && multiple == true ? [] : false
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [modalHeight, setModalHeight] = useState("auto");
  const [date, setDate] = useState(defaultDate);
  const [dotations, setDotations] = useState([]);
  const [userId, setUserId] = useState(0);
  const [agent, setAgent] = useState(false);

  useEffect(() => {
    const getBaseData = async () => {
      try {
        if (multiple) {
          // Multiple way: loop on the data.
          let userIdGlobal = 0;
          let agentsDotations = {}, transferDotations = {}, transferAgents = {};

          // Wait all promises, make an array for each agent with their
          // dotations (same as simple way)
          // Also init transferDotations and transferAgents arrays to fulfilled
          // them in the main step.
          await Promise.all(
            data.map(async (agent) => {
              const agentJson = JSON.parse(agent);
              userIdGlobal = agentJson.userId;
              transferDotations[agentJson.registrationNumber] = false;
              transferAgents[agentJson.id] = false;

              const responseDotations = await getAgentDotations(agentJson.id);
              agentsDotations[agentJson.id] = responseDotations.dotations;
            })
          );

          // Set up states ready to use.
          setDotations(agentsDotations);
          setUserId(userIdGlobal);
          setTransferDotation(transferDotations);
          setSelectedAgentId(transferAgents);
        } else {
          // Simple way : get dotations and user.
          const responseDotations = await getAgentDotations(data.id);
          setDotations(responseDotations.dotations);
          setUserId(data.userId);
        }
      } catch (e) {
        console.log("Erreur lors de la récupération des données de l'agent: ", e);
      }
    };

    getBaseData();
  }, []);

  const steps = {
    start: (
      <StepMain
        setCurrentStep={setCurrentStep}
        data={data}
        selectedAgentId={selectedAgentId}
        setSelectedAgentId={setSelectedAgentId}
        setTransferDotation={setTransferDotation}
        transferDotation={transferDotation}
        date={date}
        setDate={setDate}
        userId={userId}
        agent={agent}
        setAgent={setAgent}
      />
    ),
    start_multiple: (
      <StepMainMultiple
        setCurrentStep={setCurrentStep}
        data={data}
        selectedAgentId={selectedAgentId}
        setSelectedAgentId={setSelectedAgentId}
        setTransferDotation={setTransferDotation}
        transferDotation={transferDotation}
        date={date}
        setDate={setDate}
        userId={userId}
        agent={agent}
        setAgent={setAgent}
        defaultDate={defaultDate}
      />
    ),
    loading: (
      <StepLoading
        selectedAgentId={selectedAgentId}
        date={date}
        setCurrentStep={setCurrentStep}
        setErrorMessage={setErrorMessage}
        dotations={dotations}
        data={data}
        agent={agent}
        multiple={multiple}
      />
    ),
    success: (
      <StepResult
        data={data}
        isSuccess={true}
        multiple={multiple}
        message={i18n.t("transferSuccess")}
        setCurrentStep={setCurrentStep}
      />
    ),
    error: (
      <StepResult
        data={data}
        isSuccess={false}
        multiple={multiple}
        message={errorMessage}
        setCurrentStep={setCurrentStep}
      />
    ),
  };

  return (
    <ModalWrapper
      modalHeight={modalHeight}
      setModalHeight={setModalHeight}
      currentStep={currentStep}
      modalTitle={"agentDesactivate"}
    >
      {steps[currentStep]}
    </ModalWrapper>
  );
};

export default props => <ModuleAgentDesactivation {...props} />;
