import { call } from "./apiCall";

/**
 * Mark a freshly generated tag
 */
export const getNotifications = async () => {
  const response = await call(
    '/v1/admin/notifications/getNotifications',
    'GET'
  );

  if (response.success) {
    return JSON.parse(response.response);
  }

  return false;
};

export const getNotificationsCounter = async () => {
  const response = await call(
    '/v1/admin/notifications/getNotificationsCounter',
    'GET'
  );

  if (response.success) {
    return response.response;
  }

  return false;
};

export const patchNotifications = async () => {
  // Hardcoding params as notifications should not
  // receive any other updates than the state
  // from adminGeorges.
  const response = await call(
    '/v1/admin/notifications/patchNotifications',
    'patch',
    JSON.stringify('state=1'),
  );

  if (response.success) {
    return true;
  }

  // Trigger toaster error.
  return false;
};

export const deleteNotification = async (notificationId) => {
  const response = await call(
    `/v1/admin/notifications/${notificationId}/delete`,
    'GET'
  );

  if (response.success) {
    return response.response;
  }

  return false;
};

export const deleteNotifications = async () => {
  const response = await call(
    `/v1/admin/notifications/delete`,
    'GET'
  );

  if (response.success) {
    return response.response;
  }

  return false;
};
