import React, { useContext, useEffect } from 'react';
import { TransferContext } from '../context/TransferProvider';
import { CLEAR_MODAL } from '../context/TransferReducer';
import { MODAL_TYPE } from '../types/transferTypes';
import { ENTER_KEY } from '../config';
var getLabelByType = function (type) {
    switch (type) {
        case MODAL_TYPE.INFO:
            return 'Information';
        case MODAL_TYPE.ERROR:
            return 'Attention';
        default:
            return '';
    }
};
var getIcon = function (type) {
    switch (type) {
        case MODAL_TYPE.INFO:
            return React.createElement("i", { className: "icon circle info" });
        case MODAL_TYPE.ERROR:
            return React.createElement("i", { className: "exclamation triangle icon" });
        default:
            return null;
    }
};
var Modal = function () {
    var _a = useContext(TransferContext), state = _a[0], dispatch = _a[1];
    /**
     * Close on Enter key press
     */
    useEffect(function () {
        document.addEventListener('keyup', function (e) { return e.key === ENTER_KEY && dispatch({ type: CLEAR_MODAL }); });
        return function () { return document.addEventListener('keyup', function (e) { return e.key === ENTER_KEY && dispatch({ type: CLEAR_MODAL }); }); };
    }, []);
    var closeModal = function (event) {
        if (event.target.classList.contains('trigger-close')) {
            dispatch({ type: CLEAR_MODAL });
        }
    };
    if (state.modal !== null) {
        return (React.createElement("div", { className: "ui dimmer modals page transition visible active trigger-close", onClick: closeModal },
            React.createElement("div", { className: "ui modal transition visible active", id: "reformed-warning-modal", style: { marginTop: '-109px', display: 'block !important' } },
                React.createElement("div", { className: "header" }, getLabelByType(state.modal.type)),
                React.createElement("div", { className: "content" },
                    React.createElement("div", { className: "modal--icon" }, getIcon(state.modal.type)),
                    React.createElement("div", { className: "modal--message" },
                        state.modal.label ? React.createElement("p", null, state.modal.label) : null,
                        React.createElement("p", null, state.modal.message))),
                React.createElement("div", { className: "actions" },
                    React.createElement("button", { className: "ui black positive button trigger-close", onClick: closeModal }, "OK")))));
    }
    return null;
};
export default Modal;
