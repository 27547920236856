import React, {useState} from "react";

import Container from "../../../grid/container";
import Row from "../../../grid/row";
import Col from "../../../grid/col";

import AgentSelection from "../../../shared/form/agentSelection";
import PlacesSelection from "../../../shared/form/placesSelection";
import InputNumber from "../../../shared/form/inputNumber";
import InputCheckbox from "../../../shared/form/inputCheckbox";
import InfoBox from "../../../shared/infoBox";
import Button from "../../../shared/button";

import i18n from "../../../../libs/i18n/i18n";

const StepMainMultiple = ({
  setCurrentStep,
  data,
  userId,
  setTransferDotation,
  transferDotation,
  clients,
  deliveryPlaceSelected,
  setDeliveryPlaceSelected,
  infrastructureSelected,
  setInfrastructureSelected,
  serviceSelected,
  setServiceSelected,
  setSelectedAgentId,
  selectedAgentId,
  casier,
  armoire,
  setCasier,
  setArmoire,
  setAgent,
}) => {
  const [clientSelected, setClientSelected] = useState(false);
  const [infoBox, setInfoBox] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [nbAgents, setNbAgents] = useState(data.length);
  const [counter, setCounter] = useState(1);
  const [showTransfer, setShowTransfer] = useState(false);

  const agentDecoded = JSON.parse(data[counter - 1]);
  if (!showTags && agentDecoded.tagsNumber && agentDecoded.tagsNumber > 0) {
    setShowTags(true);
  }

  if (
    !clientSelected ||
    !deliveryPlaceSelected ||
    !infrastructureSelected ||
    !serviceSelected
  ) {
    return (
      <Row className="rform__row">
        <Col sm={3}>
          <label htmlFor="agents">{i18n.t("newDeliveryPlace")}:</label>
        </Col>

        <Col sm={9} column={true}>
          <PlacesSelection
            data={agentDecoded}
            userId={userId}
            agentId={agentDecoded.id}
            clients={clients}
            clientSelected={clientSelected}
            setClientSelected={setClientSelected}
            deliveryPlaceSelected={deliveryPlaceSelected}
            setDeliveryPlaceSelected={setDeliveryPlaceSelected}
            infrastructureSelected={infrastructureSelected}
            setInfrastructureSelected={setInfrastructureSelected}
            serviceSelected={serviceSelected}
            setServiceSelected={setServiceSelected}
            setInfoBox={setInfoBox}
            multiple={true}
          />
        </Col>
      </Row>
    );
  }

  return (
    <Container className="rform">
      <Row className="rform__row">
        <Col sm={3} className={"d-flex align-items-center"}>
          Porteur {counter} / {nbAgents}
        </Col>
      </Row>

      <Row className="rform__row">
        <Col sm={3}>{i18n.t("agentToMutate")}:</Col>
        <Col sm={7} column={true}>
          {agentDecoded && (
            <>
              <span className="rform__info rform__info--bold">
                {agentDecoded.firstname} {agentDecoded.name} - {agentDecoded.registrationNumber}
              </span>
              <span className="rform__info">
                <p>
                  Dotation: {agentDecoded.tagsNumber} vêtements <br /> {agentDecoded.clientName} -
                  {agentDecoded.infrastructure} - {agentDecoded.service} - {agentDecoded.deliveryPlace}
                  <br />
                  {"Armoire: "}{agentDecoded.wardrobe && agentDecoded.wardrobe.toString().length < 2 ? '0' + agentDecoded.wardrobe : agentDecoded.wardrobe} {" - "}
                  {"Casier: "} {agentDecoded.locker && agentDecoded.locker.toString().length < 2 ? '0' + agentDecoded.locker : agentDecoded.locker}
                </p>
              </span>
            </>
          )}
        </Col>
      </Row>

      <Row className="rform__row">
        <Col sm={3} className="d-flex align-items-center">
          {i18n.t("newCasier")}:
        </Col>
        <Col sm={9}>
          <Row>
            <Col sm={3}>
              <InputNumber
                placeholder={"armoire"}
                selectedAction={value => {
                  armoire[agentDecoded.id] = value;
                  setArmoire(armoire);
                }}
                agentId={agentDecoded.id}
              />
            </Col>
            <Col sm={3}>
              <InputNumber
                placeholder={"casier"}
                selectedAction={value => {
                  casier[agentDecoded.id] = value;
                  setCasier(casier);
                }}
                agentId={agentDecoded.id}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {showTags ? (
        <>
          <Row className="rform__row">
            <Col sm={3}>{i18n.t("dotationTransfert")}:</Col>
            <Col sm={9}>
              <InputCheckbox
                checkedAction={checked => {
                  transferDotation[agentDecoded.registrationNumber] = checked;
                  setShowTransfer(checked);
                  setTransferDotation(transferDotation);
                }}
                label={"transferDotationsWithAgent"}
                counter={counter}
              />
            </Col>
          </Row>

          <AgentSelection
            setSelectedAgentId={setSelectedAgentId}
            selectedAgentId={selectedAgentId}
            agentId={agentDecoded.id}
            multiple={true}
            setAgent={setAgent}
            display={showTransfer}
          />
        </>
      ) : (
        false
      )}

      <Row className="justify-content-center">
        <Col sm={4}>
          <Button
            color={"red"}
            tradKey={"cancel"}
            onClick={() => document.dispatchEvent(new CustomEvent("onReactPoppinClose"))}
            show={true}
          />
        </Col>

        {counter == nbAgents ? (
          <Col sm={4}>
            <Button
              color={"green"}
              tradKey={"validate"}
              onClick={() => setCurrentStep("loading")}
              show={true}
            />
          </Col>
        ) : (
          <Col sm={5}>
            <Button
              color={"orange"}
              tradKey={"next_agent"}
              onClick={() => {
                setShowTransfer(false);
                setShowTags(false);
                setCounter(counter + 1);
              }}
              show={true}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default StepMainMultiple;
