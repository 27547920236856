import React, {useState, useEffect} from "react";

import Row from "../../grid/row";
import Col from "../../grid/col";
import InputSearch from "./inputSearch";
import InputDropdown from "./inputDropdown";
import InfoBox from "../infoBox";

import {checkTransferRules, searchAgents} from "../../../api/dotationTransfer";
import i18n from "../../../libs/i18n/i18n";

const AgentSelection = ({
  setSelectedAgentId,
  selectedAgentId,
  agentId,
  setAgent,
  display,
  multiple,
}) => {
  const [infoBox, setInfoBox] = useState(false);
  const [agents, setAgents] = useState([]);
  const [placeholder, setPlaceholder] = useState("agentsList");

  const searchAgent = async slug => {
    const agentsResults = await searchAgents(slug);

    if (!agentsResults.length) {
      setPlaceholder("agentNoResults");
    }

    setAgents(agentsResults);
  };

  useEffect(() => {
    const checkRules = async () => {
      if (multiple) {
        const transferInfos = await checkTransferRules(agentId, selectedAgentId[agentId]);
        setInfoBox(transferInfos);
      } else {
        const transferInfos = await checkTransferRules(agentId, selectedAgentId);
        setInfoBox(transferInfos);
      }
    };

    const currentSelectAgentId = multiple == true
      ? selectedAgentId[agentId] : selectedAgentId;
    if (currentSelectAgentId) {
      const agentFound = agents.find(
        agent => parseInt(agent.value) === parseInt(currentSelectAgentId)
      );

      setAgent(agentFound);
      checkRules();
    }
  }, [selectedAgentId]);

  if (display) {
    return (
      <Row className="rform__row">
        <Col sm={3}>
          <label htmlFor="agents">{i18n.t("transferToAgent")}:</label>
        </Col>

        <Col sm={7} column={true}>
          <InputSearch
            className="rform__input"
            placeholder={
              i18n.t("name") +
              ", " +
              i18n.t("firstname") +
              ", " +
              i18n.t("registrationNumber")
            }
            searchAction={value => searchAgent(value)}
          />
          <InputDropdown
            name="agents"
            placeholder={placeholder}
            selectedAction={value => {
              if (multiple) {
                selectedAgentId[agentId] = value;
                setSelectedAgentId(selectedAgentId);
              } else {
                setSelectedAgentId(value);
              }
            }}
            autoOpenOnResult={true}
            results={agents}
          />
          <InfoBox infoBox={infoBox} />
        </Col>
      </Row>
    );
  }

  return false;
};

export default AgentSelection;
