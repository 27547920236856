import React, { useState } from 'react';
import { STEP } from '../../../types/modalTypes';
import ModalWrapper from '../../shared/modal/modalWrapper';
import StepMain from './components/StepMain';
import StepLoading from './components/StepLoading';
import StepResult from '../../shared/modal/DotationStepResult';
import i18n from '../../../libs/i18n/i18n';
var ModuleReactiveDotation = function (_a) {
    var _b;
    var data = _a.data;
    var _c = useState(STEP.START), currentStep = _c[0], setCurrentStep = _c[1];
    var _d = useState(null), agent = _d[0], setAgent = _d[1];
    var _e = useState(null), errorMessage = _e[0], setErrorMessage = _e[1];
    var steps = (_b = {},
        _b[STEP.START] = (React.createElement(StepMain, { data: data, agent: agent, setAgent: setAgent, setCurrentStep: setCurrentStep })),
        _b[STEP.LOADING] = (React.createElement(StepLoading, { data: data, agent: agent, setCurrentStep: setCurrentStep, setErrorMessage: setErrorMessage })),
        _b[STEP.SUCCESS] = (React.createElement(StepResult, { data: data, message: i18n.t('tagActivity.reactive_success'), isSuccess: true })),
        _b[STEP.ERROR] = (React.createElement(StepResult, { data: data, message: errorMessage, isSuccess: false })),
        _b);
    return (React.createElement(ModalWrapper, { currentStep: currentStep, modalTitle: "reactivateDotation" }, steps[currentStep]));
};
export default ModuleReactiveDotation;
