var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import "antd/dist/antd.css";
import { Button, Divider, Input, Select, Space } from 'antd';
import { getAddress, getAddressBlocks, postAddressBlock, postAddressLocal } from '../../../api/address';
import { PlusOutlined } from '@ant-design/icons';
var AddressLocalBlockSelect = function (_a) {
    var deliveryPlaceId = _a.deliveryPlaceId, addressId = _a.addressId, localId = _a.localId, blockId = _a.blockId;
    var _b = useState(), newLocalName = _b[0], setNewLocalName = _b[1];
    var _c = useState(), newBlockName = _c[0], setNewBlockName = _c[1];
    var _d = useState(), localSelected = _d[0], setLocalSelected = _d[1];
    var _e = useState(), blockSelected = _e[0], setBlockSelected = _e[1];
    var _f = useState(), addressSelected = _f[0], setAddressSelected = _f[1];
    var _g = useState([]), addressLocals = _g[0], setAddressLocals = _g[1];
    var _h = useState([]), addressBlocks = _h[0], setAddressBlocks = _h[1];
    var dispatchReactEvent = function (event, values) {
        document.dispatchEvent(new CustomEvent(event, {
            detail: values,
        }));
    };
    var fetchAndSetAddressAndLocals = function (addressId) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAddress(addressId)];
                case 1:
                    data = _a.sent();
                    setAddressSelected(data);
                    setAddressLocals(data.addressLocals.map(function (d) { return ({ label: d.name, value: d.id }); }));
                    return [2 /*return*/];
            }
        });
    }); };
    var fetchAndSetAddressBlocks = function (localId) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAddressBlocks(localId)];
                case 1:
                    data = _a.sent();
                    setAddressBlocks(data.map(function (d) { return ({ label: d.name, value: d.id }); }));
                    return [2 /*return*/];
            }
        });
    }); };
    var addLocal = function () { return __awaiter(void 0, void 0, void 0, function () {
        var local;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!newLocalName) return [3 /*break*/, 2];
                    return [4 /*yield*/, postAddressLocal(newLocalName, addressSelected)];
                case 1:
                    local = _a.sent();
                    setLocalSelected(local.id);
                    setAddressLocals(__spreadArray(__spreadArray([], addressLocals, true), [{ value: local.id, label: local.name }], false));
                    setNewLocalName(undefined);
                    dispatchReactEvent("onAddressLocalSelected", {
                        localId: local.id,
                        localName: local.name
                    });
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var addBlock = function () { return __awaiter(void 0, void 0, void 0, function () {
        var block;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!newBlockName) return [3 /*break*/, 2];
                    return [4 /*yield*/, postAddressBlock(newBlockName, localSelected)];
                case 1:
                    block = _a.sent();
                    setBlockSelected(block.id);
                    setAddressBlocks(__spreadArray(__spreadArray([], addressBlocks, true), [{ value: block.id, label: block.name }], false));
                    setNewBlockName(undefined);
                    dispatchReactEvent("onAddressBlockSelected", {
                        blockId: block.id,
                        blockName: block.name
                    });
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        fetchAndSetAddressAndLocals(addressId);
        if (localId) {
            setLocalSelected(localId);
            fetchAndSetAddressBlocks(localId);
        }
        if (blockId) {
            setBlockSelected(blockId);
        }
        document.addEventListener('onAddressSelected', function (e) {
            setLocalSelected(undefined);
            setBlockSelected(undefined);
            setNewLocalName(undefined);
            setNewBlockName(undefined);
            dispatchReactEvent("onAddressLocalClear", {});
            dispatchReactEvent("onAddressBlockClear", {});
            fetchAndSetAddressAndLocals(e.detail.addressId);
        });
        return function () { return document.removeEventListener('onAddressSelected', function () { return null; }); };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("strong", null, "B\u00E2timent"),
        React.createElement(Select, { style: { width: '100%' }, value: localSelected, allowClear: true, options: addressLocals.map(function (a) { return ({
                value: a.value,
                label: a.label
            }); }), onSelect: function (value, option) {
                setLocalSelected(option.value);
                fetchAndSetAddressBlocks(option.value);
                dispatchReactEvent("onAddressLocalSelected", {
                    localId: option.value,
                    localName: option.label
                });
            }, onClear: function () {
                setLocalSelected(undefined);
                setBlockSelected(undefined);
                dispatchReactEvent("onAddressLocalClear", {});
                dispatchReactEvent("onAddressBlockClear", {});
            }, dropdownRender: function (menu) { return (React.createElement(React.Fragment, null,
                menu,
                React.createElement(Divider, { style: { margin: '8px 0' } }),
                React.createElement(Space, { style: { padding: '0 8px 4px' } },
                    React.createElement(Input, { placeholder: "Please enter item", onChange: function (e) { return setNewLocalName(e.target.value); } }),
                    React.createElement(Button, { type: "text", icon: React.createElement(PlusOutlined, null), onClick: addLocal }, "Ajouter un b\u00E2timent")))); } }),
        React.createElement("strong", null, "Pi\u00E8ce"),
        React.createElement(Select, { style: { width: '100%' }, value: blockSelected, allowClear: true, options: addressBlocks.map(function (a) { return ({
                value: a.value,
                label: a.label
            }); }), onSelect: function (value, option) {
                setBlockSelected(option);
                dispatchReactEvent("onAddressBlockSelected", {
                    blockId: option.value,
                    blockName: option.label
                });
            }, onClear: function () {
                setBlockSelected(undefined);
                dispatchReactEvent("onAddressBlockClear", {});
            }, dropdownRender: function (menu) { return (React.createElement(React.Fragment, null,
                menu,
                React.createElement(Divider, { style: { margin: '8px 0' } }),
                React.createElement(Space, { style: { padding: '0 8px 4px' } },
                    React.createElement(Input, { placeholder: "Please enter item", onChange: function (e) { return setNewBlockName(e.target.value); } }),
                    React.createElement(Button, { type: "text", icon: React.createElement(PlusOutlined, null), onClick: addBlock }, "Ajouter une pi\u00E8ce")))); } })));
};
export default (function (props) { return React.createElement(AddressLocalBlockSelect, __assign({}, props)); });
