import {call} from "./apiCall";

/**
 * Fetch the current alert if there is one
 */
export const fetchAlert = async () => {
  const response = await call(`/v1/admin/settings/fetch_current_alert`, "GET");

  return response;
};
