import React, {useState} from "react";
import {SingleDatePicker} from "react-dates";

import Button from "../button";

import moment from "../../../libs/moment/moment";
import i18n from "../../../libs/i18n/i18n";

const InputDate = ({date, setDate}) => {
  const [inputFocused, setInputFocused] = useState(false);

  return (
    <div className="inputDate">
      <SingleDatePicker
        date={date} // momentPropTypes.momentObj or null
        placeholder="Selectionnez une date"
        onDateChange={date => setDate(date)} // PropTypes.func.isRequired
        focused={inputFocused} // PropTypes.bool
        onFocusChange={({focused}) => setInputFocused(focused)} // PropTypes.func.isRequired
        id="your_unique_id" // PropTypes.string.isRequired,
        numberOfMonths={1}
        displayFormat="LL"
        showDefaultInputIcon={true}
        isOutsideRange={() => false}
      />
      <Button
        show={true}
        rounded={true}
        onClick={() => setDate(moment())}
        color={"orange"}
        tradKey={"today"}
      />
    </div>
  );
};

export default InputDate;
