import React, { useContext } from 'react';
import i18n from '../../../../libs/i18n/i18n';
import { TransferContext } from '../context/TransferProvider';
var TransferSummary = function () {
    var state = useContext(TransferContext)[0];
    return (React.createElement("div", { className: "transfer-summary ".concat(state.success ? 'transfer-summary--success' : 'transfer-summary--error') },
        React.createElement("div", { className: "transfer-summary--title" },
            React.createElement("i", { className: "icon ".concat(state.success ? 'check circle outline' : 'exclamation triangle') }),
            React.createElement("h2", null, state.success ? i18n.t('transfer.result.success') : i18n.t('transfer.result.error'))),
        React.createElement("div", { className: "transfer-summary--body" },
            React.createElement("div", null, state.tags.map(function (tag) { return (React.createElement("div", { key: tag.id, className: "transfer-summary--tag" },
                React.createElement("div", { className: "transfer-summary--label" }, tag.cloth.name),
                React.createElement("div", null,
                    React.createElement("span", null, tag.code),
                    React.createElement("br", null),
                    React.createElement("span", null,
                        i18n.t('transfer.result.old_agent'),
                        " : ",
                        tag.agent.name,
                        " ",
                        tag.agent.firstname,
                        " - ",
                        tag.agent.registrationNumber)))); })),
            React.createElement("div", null, state.success
                ? React.createElement("i", { className: "angle double right icon" })
                : React.createElement("i", { className: "close icon" })),
            React.createElement("div", null,
                React.createElement("div", { className: "transfer-summary--agent" },
                    React.createElement("i", { className: "user icon" }),
                    React.createElement("div", null,
                        React.createElement("span", { className: "transfer-summary--label" },
                            state.agent.name,
                            " ",
                            state.agent.firstname),
                        React.createElement("br", null),
                        React.createElement("span", null, state.agent.registrationNumber)))))));
};
export default TransferSummary;
