import React, {useState, useEffect} from "react";
import Menu from "../menu";

const MainLayout = props => {
  return (
    <>
      {props.heading}

      <div className="mainLayout">
        <Menu />
        {props.children}
      </div>
    </>
  );
};

export default props => <MainLayout {...props} />;
