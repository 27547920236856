import React, { useContext, useEffect, useRef } from 'react';
import { TransferContext } from '../context/TransferProvider';
import TagToTransfer from './TagToTransfer';
var TagsToTransfer = function () {
    var state = useContext(TransferContext)[0];
    var tagContainer = useRef(null);
    useEffect(function () {
        if (tagContainer && state.tags.length > 0) {
            tagContainer.current.scroll({ top: tagContainer.current.scrollHeight });
        }
    }, [state.tags, tagContainer]);
    return (React.createElement("div", { className: "tags-to-transfer ".concat(state.tags.length !== 0 && 'shadow-container'), ref: tagContainer }, state.tags.length !== 0 && (React.createElement("div", { className: "tags" }, state.tags.map(function (tag) { return (React.createElement(TagToTransfer, { key: tag.id, tag: tag })); })))));
};
export default TagsToTransfer;
