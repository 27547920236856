import React from 'react';
import { TagActivityRule } from 'georges-types-kit';
import Col from '../../../grid/col';
import i18n from '../../../../libs/i18n/i18n';
import Row from '../../../grid/row';
var ReactivationRule = function (_a) {
    var rule = _a.rule;
    return (React.createElement(Row, { className: "rform__row" },
        React.createElement(Col, { sm: 3 },
            i18n.t('tagActivity.reactivationRule.placeholder'),
            ":"),
        React.createElement(Col, { sm: 7, column: true },
            React.createElement("span", { className: "rform__info rform__info--bold" },
                rule === TagActivityRule.BACK_TO_AGENT && i18n.t('tagActivity.reactivationRule.backToAgent'),
                rule === TagActivityRule.BACK_TO_STOCK && i18n.t('tagActivity.reactivationRule.backToStock')))));
};
export default ReactivationRule;
