var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { MODAL_TYPE } from '../types/transferTypes';
import { ADD_TAG, SET_MODAL, SET_SUCCESS, SET_TAGS } from './TransferReducer';
import i18n from '../../../../libs/i18n/i18n';
import { checkScanToTransfer, refreshTagToTransfer, transferMultiple } from '../../../../api/tag';
/**
 * Action to check if scan be transferred. Display message if can't.
 * @param code
 */
export var scanToTransfer = function (code) { return function (dispatch, state) { return __awaiter(void 0, void 0, void 0, function () {
    var response, label, message, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (code.trim().length === 0) {
                    return [2 /*return*/];
                }
                // Missing agent
                if (!state.agent || !state.agent.id) {
                    return [2 /*return*/, dispatch({ type: SET_MODAL, payload: {
                                type: MODAL_TYPE.ERROR,
                                message: i18n.t('transfer.error.missing_agent'),
                            } })];
                }
                // Already scanned.
                if (state.tags.find(function (tag) { return tag.code === code; }) !== undefined) {
                    return [2 /*return*/, dispatch({ type: SET_MODAL, payload: {
                                type: MODAL_TYPE.INFO,
                                message: i18n.t('transfer.info.already_in_tags'),
                            } })];
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, checkScanToTransfer(code, state.agent.id)];
            case 2:
                response = _a.sent();
                if (response.success) {
                    return [2 /*return*/, dispatch({ type: ADD_TAG, payload: response.tag })];
                }
                label = response.clothsNotFound ? "".concat(code, " : ").concat(response.clothsNotFound[0]) : code;
                message = response.message || i18n.t('transfer.error.transfer_impossible');
                dispatch({ type: SET_MODAL, payload: { type: MODAL_TYPE.ERROR, label: label, message: message } });
                return [3 /*break*/, 4];
            case 3:
                e_1 = _a.sent();
                console.error("An error occurred : ".concat(e_1));
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); }; };
/**
 * Get all tags data to refresh display.
 */
export var getTags = function () { return function (dispatch, state) { return __awaiter(void 0, void 0, void 0, function () {
    var tagsCode, response, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                tagsCode = state.tags.map(function (tag) { return tag.code; });
                return [4 /*yield*/, refreshTagToTransfer(tagsCode)];
            case 1:
                response = _a.sent();
                if (Array.isArray(response)) {
                    return [2 /*return*/, dispatch({ type: SET_TAGS, payload: response })];
                }
                return [3 /*break*/, 3];
            case 2:
                e_2 = _a.sent();
                console.error("An error occurred : ".concat(e_2));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
/**
 * Transfer tags to chosen agent.
 */
export var transferTags = function () { return function (dispatch, state) { return __awaiter(void 0, void 0, void 0, function () {
    var tagsIds, response, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                tagsIds = state.tags.map(function (tag) { return tag.id; });
                if (!state.agent || !state.agent.id) {
                    return [2 /*return*/, dispatch({ type: SET_MODAL, payload: { type: MODAL_TYPE.ERROR, message: i18n.t('transfer.error.missing_agent') } })];
                }
                if (state.tags.length === 0) {
                    return [2 /*return*/, dispatch({ type: SET_MODAL, payload: { type: MODAL_TYPE.ERROR, message: i18n.t('transfer.error.missing_tags') } })];
                }
                return [4 /*yield*/, transferMultiple(tagsIds, state.agent.id)];
            case 1:
                response = _a.sent();
                dispatch({ type: SET_SUCCESS, payload: response });
                return [3 /*break*/, 3];
            case 2:
                e_3 = _a.sent();
                console.error("An error occurred : ".concat(e_3));
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); }; };
