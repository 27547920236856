import React from 'react';
import classNames from 'classnames'

const DEVICE_SIZES = ['xl', 'lg', 'md', 'sm', 'xs'];

const Col = (props) => {
    const { children, className, column } = props;
    const spans = [];
    const classes = [];

    DEVICE_SIZES.forEach(brkPoint => {
        let propValue = props[brkPoint];

        let span, offset, order;
        if (propValue != null && typeof propValue === 'object') {
            ({ span = true, offset, order } = propValue);
        } else {
            span = propValue;
        }

        let infix = brkPoint !== 'xs' ? `-${brkPoint}` : '';

        if (span != null)
            spans.push(
                span === true ? `col${infix}` : `col${infix}-${span}`,
            );

        if (order != null) classes.push(`order${infix}-${order}`);
        if (offset != null) classes.push(`offset${infix}-${offset}`);
    });

    if (column) {
        classes.push('d-flex flex-column')
    }

    return (
        <div className={classNames(className, ...spans, ...classes)}>
            {children}
        </div>
    );
};

export default Col;