var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import IconTriangle from '../../../../svg/iconTriangle';
import Spinner from '../spinner';
var Autocomplete = function (props) {
    var placeholder = props.placeholder, defaultValue = props.defaultValue, onChange = props.onChange, label = props.label, onSelect = props.onSelect, disabled = props.disabled, onClear = props.onClear;
    var _a = useState(defaultValue), value = _a[0], setValue = _a[1];
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState([]), results = _c[0], setResults = _c[1];
    useEffect(function () {
        setValue(defaultValue);
    }, [defaultValue]);
    useEffect(function () {
        if (value.trim().length >= 3) {
            var timeout_1 = setTimeout(function () { return handleChange(value); }, 300);
            return function () { return clearTimeout(timeout_1); };
        }
    }, [value]);
    var handleChange = function (search) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, onChange(search)];
                case 1:
                    response = _a.sent();
                    setResults(response);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleSelect = function (element) {
        if (!('active' in element) || element.active !== false) {
            onSelect(element);
            setResults([]);
        }
    };
    var clear = function () {
        setValue('');
        setResults([]);
        onClear();
    };
    return (React.createElement("div", { className: "rdropdown" },
        React.createElement("div", { className: "rdropdown__input-container" },
            React.createElement("input", { placeholder: placeholder, value: value, onChange: function (e) { return setValue(e.target.value); }, disabled: disabled }),
            React.createElement("div", { className: "rdropdown__close ".concat(disabled && 'disabled'), onClick: clear, onKeyPress: clear },
                React.createElement("i", { className: "close icon" })),
            React.createElement("div", { className: "rdropdown__icon" },
                React.createElement(IconTriangle, { color: "black" }))),
        (results.length > 0 || loading) && (React.createElement("div", { className: "rdropdown__list active" }, loading
            ? React.createElement(Spinner, null)
            : results.map(function (result) { return (React.createElement("div", { key: typeof result === 'object' ? result.id : result, onClick: function () { return handleSelect(result); }, className: result.active === false ? 'inactive' : '' }, label(result))); })))));
};
export default Autocomplete;
