import React, {useState, useEffect} from "react";

import Container from "../../../grid/container";
import Row from "../../../grid/row";
import Col from "../../../grid/col";
import AgentSelection from "../../../shared/form/agentSelection";
import Button from "../../../shared/button";
import InputCheckbox from "../../../shared/form/inputCheckbox";
import InputDate from "../../../shared/form/inputDate";

import i18n from "../../../../libs/i18n/i18n";
import moment from "../../../../libs/moment/moment";

const StepMainMultiple = ({
  setCurrentStep,
  data,
  selectedAgentId,
  setSelectedAgentId,
  setTransferDotation,
  transferDotation,
  date,
  setDate,
  userId,
  agent,
  setAgent,
  defaultDate
}) => {
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [nbAgents, setNbAgents] = useState(data.length);
  const [counter, setCounter] = useState(1);
  const [showTransfer, setShowTransfer] = useState(false);

  const agentDecoded = JSON.parse(data[counter - 1]);
  if (!showTags && agentDecoded.tagsNumber && agentDecoded.tagsNumber > 0) {
    setShowTags(true);
  }

  if (date == '' || moment(date).isSame(defaultDate, 'day')) {
    return (
      <Container className="rform">
        <Row className="rform__row">
          <Col sm={3} className={"d-flex align-items-center"}>
            Date de fin d'activité pour l'ensemble des porteurs:
          </Col>
          <Col sm={9}>
            <InputDate date={date} setDate={setDate} />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="rform">
      <Row className="rform__row">
        <Col sm={3} className={"d-flex align-items-center"}>
          Porteur {counter} / {nbAgents}
        </Col>
      </Row>

      <Row className="rform__row">
        <Col sm={3}>{i18n.t("agentToDesactivate")}:</Col>
        <Col sm={7} column={true}>
          {agentDecoded && (
            <span className="rform__info">
              <span className="rform__info--bold">{agentDecoded.firstname} {agentDecoded.name} - {agentDecoded.registrationNumber} </span>
              ({agentDecoded.tagsNumber} vêtements)<br />
              {"Lieu: "}{agentDecoded.deliveryPlace} {" - "}
              {"Armoire: "}{agentDecoded.wardrobe && agentDecoded.wardrobe.toString().length < 2 ? '0' + agentDecoded.wardrobe : agentDecoded.wardrobe} {" - "}
              {"Casier: "} {agentDecoded.locker && agentDecoded.locker.toString().length < 2 ? '0' + agentDecoded.locker : agentDecoded.locker}
            </span>
          )}
        </Col>
      </Row>

      {showTags ? (
        <>
          <Row className="rform__row">
            <Col sm={3}>{i18n.t("dotationTransfert")}:</Col>
            <Col sm={9}>
              <InputCheckbox
                label={"transferDotationsWithAgent"}
                checkedAction={checked => {
                  transferDotation[agentDecoded.registrationNumber] = checked;
                  setShowTransfer(checked);
                  setTransferDotation(transferDotation);
                }}
                counter={counter}
              />
            </Col>
          </Row>

          <AgentSelection
            setSelectedAgentId={setSelectedAgentId}
            selectedAgentId={selectedAgentId}
            agentId={agentDecoded.id}
            multiple={true}
            setAgent={setAgent}
            display={showTransfer}
          />
        </>
      ) : (
        false
      )}

      {counter == nbAgents && (
        <Row className="rform__row">
          <Col sm={12}>
            <InputCheckbox
              label={"understandDesactivationMultiples"}
              checkedAction={checked => setAcceptTerms(checked)}
            />
          </Col>
        </Row>
      )}

      <Row className="justify-content-center">
        <Col sm={5}>
          <Button
            color={"red"}
            tradKey={"cancel"}
            onClick={() => document.dispatchEvent(new CustomEvent("onReactPoppinClose"))}
            show={true}
          />
        </Col>

        {counter == nbAgents ? (
          <Col sm={5}>
            <Button
              color={"green"}
              tradKey={"validate"}
              onClick={() => setCurrentStep("loading")}
              show={acceptTerms && date}
            />
          </Col>
        ) : (
          <Col sm={5}>
            <Button
              color={"orange"}
              tradKey={"next_agent"}
              onClick={() => {
                setShowTransfer(false);
                setShowTags(false);
                setCounter(counter + 1);
              }}
              show={true}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default StepMainMultiple;
