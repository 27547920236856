import React, {useState, useEffect} from "react";

import StepMain from "./components/stepMain";
import StepMainMultiple from "./components/stepMainMultiple";
import StepLoading from "./components/stepLoading";
import StepResult from "./components/stepResult";
import ModalWrapper from "../../shared/modal/modalWrapper";
import i18n from "../../../libs/i18n/i18n";

import {getAllClients} from "../../../api/agentMutation";
import {getAgentDotations} from "../../../api/agentMutation";

const ModuleAgentMutation = ({data, multiple}) => {
  const [currentStep, setCurrentStep] = useState(
    multiple && multiple == true ? "start_multiple" : "start"
  );
  const [transferDotation, setTransferDotation] = useState(
    multiple && multiple == true ? [] : false
  );
  const [selectedAgentId, setSelectedAgentId] = useState(
    multiple && multiple == true ? [] : false
  );
  const [casier, setCasier] = useState(
    multiple && multiple == true ? [] : ""
  );
  const [armoire, setArmoire] = useState(
    multiple && multiple == true ? [] : ""
  );

  const [deliveryPlaceSelected, setDeliveryPlaceSelected] = useState(false);
  const [infrastructureSelected, setInfrastructureSelected] = useState(false);
  const [serviceSelected, setServiceSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalHeight, setModalHeight] = useState("auto");
  const [clients, setClients] = useState([]);
  const [dotations, setDotations] = useState([]);
  const [userId, setUserId] = useState(0);
  const [agent, setAgent] = useState(false);

  useEffect(() => {
    const getBaseData = async () => {
      try {
        if (multiple) {
          // Multiple way: loop on the data.
          let agentsDotations = {}, transferDotations = {}, initArray = {},
          initArrayCasier = {}, initArrayArmoire = {};
          let userIdGlobal;

          // Wait all promises, make an array for each agent with their
          // dotations (same as simple way)
          // Also init transferDotations and transferAgents arrays to fulfilled
          // them in the main step.
          await Promise.all(
            data.map(async (agent) => {
              const agentJson = JSON.parse(agent);
              userIdGlobal = agentJson.userId;
              transferDotations[agentJson.registrationNumber] = false;
              initArray[agentJson.id] = false;

              const responseDotations = await getAgentDotations(agentJson.id);
              agentsDotations[agentJson.id] = responseDotations.dotations;
            })
          );

          // Set up states ready to use.
          const responseClients = await getAllClients(userIdGlobal);
          setClients(responseClients.results);
          setDotations(agentsDotations);
          setUserId(userIdGlobal);
          setTransferDotation(transferDotations);
          setSelectedAgentId(initArray);
          setCasier(initArrayCasier);
          setArmoire(initArrayArmoire);
        } else {
          const responseClients = await getAllClients(data.userId);
          setClients(responseClients.results);

          const responseDotations = await getAgentDotations(data.id);
          setDotations(responseDotations.dotations);
        }
      } catch (e) {
        console.log("Erreur lors de la récupération des données de l'agent: ", e);
      }
    };

    getBaseData();
  }, []);

  const steps = {
    start: (
      <StepMain
        setCurrentStep={setCurrentStep}
        data={data}
        clients={clients}
        setTransferDotation={setTransferDotation}
        transferDotation={transferDotation}
        deliveryPlaceSelected={deliveryPlaceSelected}
        setDeliveryPlaceSelected={setDeliveryPlaceSelected}
        setInfrastructureSelected={setInfrastructureSelected}
        infrastructureSelected={infrastructureSelected}
        serviceSelected={serviceSelected}
        setServiceSelected={setServiceSelected}
        selectedAgentId={selectedAgentId}
        setSelectedAgentId={setSelectedAgentId}
        armoire={armoire}
        setArmoire={setArmoire}
        casier={casier}
        setCasier={setCasier}
        setAgent={setAgent}
      />
    ),
    start_multiple: (
      <StepMainMultiple
        setCurrentStep={setCurrentStep}
        data={data}
        clients={clients}
        userId={userId}
        setTransferDotation={setTransferDotation}
        transferDotation={transferDotation}
        deliveryPlaceSelected={deliveryPlaceSelected}
        setDeliveryPlaceSelected={setDeliveryPlaceSelected}
        setInfrastructureSelected={setInfrastructureSelected}
        infrastructureSelected={infrastructureSelected}
        serviceSelected={serviceSelected}
        setServiceSelected={setServiceSelected}
        selectedAgentId={selectedAgentId}
        setSelectedAgentId={setSelectedAgentId}
        armoire={armoire}
        setArmoire={setArmoire}
        casier={casier}
        setCasier={setCasier}
        setAgent={setAgent}
      />
    ),
    loading: (
      <StepLoading
        dotations={dotations}
        data={data}
        transferDotation={transferDotation}
        setErrorMessage={setErrorMessage}
        setCurrentStep={setCurrentStep}
        deliveryPlaceSelected={deliveryPlaceSelected}
        infrastructureSelected={infrastructureSelected}
        serviceSelected={serviceSelected}
        selectedAgentId={selectedAgentId}
        armoire={armoire}
        casier={casier}
        agent={agent}
        multiple={multiple}
      />
    ),
    success: (
      <StepResult
        data={data}
        isSuccess={true}
        message={i18n.t("transferSuccess")}
        setCurrentStep={setCurrentStep}
      />
    ),
    error: (
      <StepResult
        data={data}
        isSuccess={false}
        message={errorMessage}
        setCurrentStep={setCurrentStep}
      />
    ),
  };

  return (
    <ModalWrapper
      modalHeight={modalHeight}
      setModalHeight={setModalHeight}
      currentStep={currentStep}
      modalTitle={"mutationAgent"}
    >
      {steps[currentStep]}
    </ModalWrapper>
  );
};

export default ModuleAgentMutation;
