import React, {useEffect, useRef} from "react";

import Container from "../../../grid/container";
import Row from "../../../grid/row";
import Col from "../../../grid/col";

import i18n from "../../../../libs/i18n/i18n";
import Loading from "../../../shared/loading";
import {checkClothInCatalogByAgent, setTagAgent} from "../../../../api/dotationTransfer";
import {activateAgent} from "../../../../api/agentDesactivation";

const StepLoading = ({
  setCurrentStep,
  selectedAgentId,
  setErrorMessage,
  data,
  date,
  dotations,
  deliveryPlaceSelected,
  serviceSelected,
  armoire,
  casier,
  agent,
}) => {
  useEffect(() => {
    const check = async () => {
      try {
        if (selectedAgentId) {
          const isInCatalog = await checkClothInCatalogByAgent(
            selectedAgentId,
            dotations.map(dotation => dotation.clothId)
          );

          if (isInCatalog.success) {
            await setTagAgent(
              dotations.map(dotation => dotation.id),
              selectedAgentId
            );

            await activateAgent(
              data.id,
              date,
              serviceSelected,
              deliveryPlaceSelected,
              armoire,
              casier
            );

            setCurrentStep("success");
          } else {
            setErrorMessage(
              i18n.t("transferError", {
                clothsNotFound: isInCatalog.clothsNotFound,
                catalogs: isInCatalog.catalogs,
                infrastructure: agent.infrastructure.name,
              })
            );
            setCurrentStep("error");
          }
        } else {
          await activateAgent(
            data.id,
            date,
            serviceSelected,
            deliveryPlaceSelected,
            armoire,
            casier
          );
          setCurrentStep("success");
        }
      } catch (e) {
        console.log("Erreur lors de l'opération: ", e);
        setErrorMessage("Erreur lors de l'opération");
        setCurrentStep("error");
      }
    };

    window.setTimeout(() => check(), 1000);
  }, []);

  return (
    <div className="loading rpoppin__loading">
      <Container>
        <Row className={"justify-content-center"}>
          <Col sm={8}>
            <div className="rpoppin__loading__title">
              Agent à désactiver:{" "}
              <span>
                {data.name} {data.firstname} - {data.registrationNumber}
              </span>
            </div>
            <p>En cours...</p>
          </Col>
        </Row>
        <Loading />
      </Container>
    </div>
  );
};

export default StepLoading;
