import React, { useState } from 'react';
import { ControlEventType } from 'georges-types-kit';
import useEventDisplay from '../../shared/hooks/useEventDisplay';
import '../../../../css/modules/askReform/base.scss';
import '../../../../css/modules/controlTag/base.scss';
import useControlTag from './useControlTag';
var ControlTag = function () {
    var _a = useState(null), controlEvent = _a[0], setControlEvent = _a[1];
    var _b = useState(null), tagId = _b[0], setTagId = _b[1];
    var _c = useControlTag(), loading = _c.loading, error = _c.error, getStatus = _c.getStatus, handleNewControl = _c.handleNewControl, handleSendEstimate = _c.handleSendEstimate, estimateSent = _c.estimateSent, setEstimateSent = _c.setEstimateSent;
    useEventDisplay('initControlTag', null, function (e) {
        var controlEvt = e.detail.data.controlEvent;
        setControlEvent(controlEvt);
        setTagId(e.detail.data.id);
        setEstimateSent(controlEvt.repairEstimate && controlEvt.repairEstimate.sentAt !== null);
    });
    if (!controlEvent) {
        return null;
    }
    return (React.createElement("div", { className: "control" },
        React.createElement("div", { className: "title control--title" },
            "Contr\u00F4le : ",
            getStatus(controlEvent.type)),
        React.createElement("div", { className: "control--content" },
            controlEvent.controlPointEvents.map(function (controlPointEvent) { return (React.createElement("div", { className: "control--point", key: controlPointEvent.id },
                React.createElement("div", { className: "control--name" },
                    React.createElement("div", { className: "control--level control--level--".concat(controlPointEvent.controlPoint.level) },
                        "Niveau ",
                        controlPointEvent.controlPoint.level),
                    controlPointEvent.controlPoint.name),
                React.createElement("div", { className: controlPointEvent.success ? 'control--point--success' : 'control--point--error' }, controlPointEvent.success ? 'OK' : 'NOK'))); }),
            React.createElement("div", { className: "control--actions" },
                controlEvent.type === ControlEventType.SUCCESS && (React.createElement("button", { className: "ui secondary tiny button control--action ".concat(loading && 'loading'), onClick: function () { return handleNewControl(tagId); } }, "Lancer un nouveau contr\u00F4le")),
                controlEvent.type === ControlEventType.WAITING_CLIENT_APPROVAL && (React.createElement("button", { className: "ui black tiny button control--action ".concat(loading && 'loading'), onClick: function () { return handleSendEstimate(controlEvent.repairEstimate); }, disabled: loading || estimateSent }, estimateSent ? 'Devis envoyé' : 'Envoyer le devis'))),
            React.createElement("div", null, error))));
};
export default (function () { return (React.createElement(ControlTag, null)); });
