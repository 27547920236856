import React, { useContext } from 'react';
import i18n from '../../../../libs/i18n/i18n';
import { TransferContext } from '../context/TransferProvider';
import { REMOVE_TAG } from '../context/TransferReducer';
import TagState from './TagState';
import AgentPlace from './AgentPlace';
var TagsToTransfer = function (_a) {
    var tag = _a.tag;
    var _b = useContext(TransferContext), dispatch = _b[1];
    return (React.createElement("div", { className: "tag" },
        React.createElement("div", { className: "tag--header" },
            React.createElement("span", null,
                React.createElement("i", { className: "edit icon" }),
                tag.cloth.name),
            React.createElement("span", { className: "wash-container" },
                React.createElement("i", { className: "washing exn icon" }),
                React.createElement("span", { className: "wash-count" }, tag.washCount),
                "/",
                tag.cloth.washingLimit)),
        React.createElement(TagState, { tag: tag }),
        React.createElement("a", { href: "/logs/scans/history/".concat(tag.id), target: "_blank" },
            React.createElement("i", { className: "history icon" }),
            "Voir l'historique"),
        React.createElement("div", null,
            React.createElement("i", { className: "barcode icon" }),
            tag.code),
        React.createElement("div", null,
            React.createElement("i", { className: "expand icon" }),
            "Taille ",
            tag.clothSize ? tag.clothSize.name : ''),
        React.createElement("div", null,
            React.createElement("i", { className: "user icon" }),
            "Porteur actuel : ",
            tag.agent !== null &&
                "".concat(tag.agent.firstname, " ").concat(tag.agent.name, " - ").concat(tag.agent.registrationNumber)),
        React.createElement(AgentPlace, { agent: tag.agent }),
        React.createElement("div", { className: "tag--action" },
            React.createElement("span", { className: "text--red" }, tag.state !== 'delivered' || tag.waitingReformation ? i18n.t('transfer.tag.state_warning') : null),
            React.createElement("button", { className: "button ui button--red delete-tag", onClick: function () { return dispatch({ type: REMOVE_TAG, payload: tag.id }); } }, "Supprimer"))));
};
export default TagsToTransfer;
