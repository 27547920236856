import React, {useState, useEffect} from "react";

import Container from "../../../grid/container";
import Row from "../../../grid/row";
import Col from "../../../grid/col";
import AgentSelection from "../../../shared/form/agentSelection";
import Button from "../../../shared/button";
import AgentCard from "../../../shared/agentCard";

import IconTransmission from "../../../../../svg/transmission";
import i18n from "../../../../libs/i18n/i18n";
import DotationInformation from '../../../shared/modal/dotationInformation';

const StepMain = ({
  setCurrentStep,
  tag,
  selectedAgentId,
  setSelectedAgentId,
  agent,
  setAgent,
}) => {
  return (
    <Container className="rform">
      <DotationInformation data={tag} label="clothToTransfer" />

      <AgentSelection
        setSelectedAgentId={setSelectedAgentId}
        selectedAgentId={selectedAgentId}
        agentId={tag.agentId}
        setAgent={setAgent}
        display={true}
      />

      <Row className="rform__row justify-content-center">
        <AgentCard agentName={`${tag.agentFirstname} ${tag.agentName} ${tag.agentRegistrationNumber}`} />

        <Col sm={3} className="justify-content-center">
          <IconTransmission />
        </Col>

        <AgentCard agentName={agent && agent.name} reverse />
      </Row>

      <Row className="justify-content-center">
        <Col sm={5}>
          <Button
            color={"red"}
            tradKey={"cancel"}
            onClick={() => document.dispatchEvent(new CustomEvent("onReactPoppinClose"))}
            show={true}
          />
        </Col>
        <Col sm={5}>
          <Button
            color={"green"}
            tradKey={"validate"}
            onClick={() => setCurrentStep("loading")}
            show={selectedAgentId}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default StepMain;
