import React from "react";

export default ({color}) => (
  <svg
    width="45px"
    height="45px"
    viewBox="0 0 45 45"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Icons/Menu/Porteurs_off</title>
    <g
      id="Icons/Menu/Porteurs_off"
      stroke="none"
      stroke-width="1"
      fill={color}
      fill-rule="evenodd"
    >
      <path
        d="M22.5000001,22.4999827 C20.8376221,22.4999827 19.4857163,21.1544827 19.4857163,19.4999827 C19.4857163,17.8454827 20.8376221,16.4999827 22.5000001,16.4999827 C24.1623781,16.4999827 25.5142838,17.8454827 25.5142838,19.4999827 C25.5142838,21.1544827 24.1623781,22.4999827 22.5000001,22.4999827 M25.3319198,23.0047327 C26.5278368,22.0469827 27.2248906,20.4982327 26.9686763,18.8024827 C26.6710156,16.8352327 25.0267238,15.2609827 23.0418173,15.0314827 C20.3025871,14.7142327 17.9785741,16.8367327 17.9785741,19.4999827 C17.9785741,20.9174827 18.6387023,22.1804827 19.6680803,23.0047327 C17.1390961,23.9504827 15.2928473,26.1712327 15.0034756,29.1682327 C14.9612761,29.6114827 15.3086723,29.9999827 15.7562933,29.9999827 C16.1398606,29.9999827 16.4669108,29.7119827 16.5008213,29.3317327 C16.8030031,25.9844827 19.3779556,23.9999827 22.5,23.9999827 C25.6220446,23.9999827 28.1969971,25.9844827 28.4991788,29.3317327 C28.5330893,29.7119827 28.8601396,29.9999827 29.2437068,29.9999827 C29.6913278,29.9999827 30.0387241,29.6114827 29.9965246,29.1682327 C29.7071528,26.1712327 27.8609041,23.9504827 25.3319198,23.0047327"
        id="profile-[#1341]"
        fill={color}
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
);
