var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var SET_AGENT = 'SET_AGENT';
export var ADD_TAG = 'ADD_TAG';
export var SET_TAGS = 'SET_TAGS';
export var REMOVE_TAG = 'REMOVE_TAG';
export var SET_MODAL = 'SET_MODAL';
export var CLEAR_MODAL = 'CLEAR_MODAL';
export var CLEAR_ALL = 'CLEAR_ALL';
export var SET_SUCCESS = 'SET_SUCCESS';
export var initialState = {
    agent: null,
    tags: [],
    modal: null,
    success: null,
    batchId: null,
    printer: null,
};
export var transferReducer = function (state, action) {
    switch (action.type) {
        case SET_AGENT:
            return __assign(__assign({}, state), { agent: action.payload });
        case ADD_TAG:
            return __assign(__assign({}, state), { tags: __spreadArray(__spreadArray([], state.tags, true), [action.payload], false) });
        case REMOVE_TAG:
            return __assign(__assign({}, state), { tags: state.tags.filter(function (tag) { return tag.id !== action.payload; }) });
        case SET_TAGS:
            return __assign(__assign({}, state), { tags: action.payload });
        case SET_MODAL:
            return __assign(__assign({}, state), { modal: action.payload });
        case CLEAR_MODAL:
            return __assign(__assign({}, state), { modal: null });
        case CLEAR_ALL:
            return initialState;
        case SET_SUCCESS:
            return __assign(__assign({}, state), { success: action.payload.success, batchId: action.payload.batch_id || null, printer: action.payload.printer || null });
        default:
            return state;
    }
};
