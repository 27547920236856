import React, {useState, useEffect} from "react";

import Container from "../../grid/container";
import Row from "../../grid/row";
import Col from "../../grid/col";
import Hosting from "../../../../svg/hosting";

import {fetchAlert} from "../../../api/settings";

const InfoPanel = () => {
  const [alert, setAlert] = useState(false);

  const colors = {
    alert: "red",
    info: "orange",
  };

  useEffect(() => {
    const execute = async () => {
      const alertFetched = await fetchAlert();
      setAlert(alertFetched);
    };

    execute();
  }, []);

  const Icon = () => {
    if (alert.type == "alert") {
      return <Hosting />;
    }

    return false;
  };

  /*const Cross = () => {
    if (alert.type == "alert") {
      return false;
    }

    return (
      <div className="infoPanel__toggle" onClick={() => setAlert(false)}>
        X
      </div>
    );
  };*/

  return (
    <Container
      className={`infoPanel infoPanel--${colors[alert.type]} ${alert ? "active" : ""}`}
      fluid={true}
    >
      <Row>
        <Col sm={12} className="d-flex justify-content-center align-items-center">
          <Icon />
          <p>{alert.message}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default props => <InfoPanel {...props} />;
