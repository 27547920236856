import React, { useState, useEffect, useRef } from "react";
import IconTriangle from "../../../../svg/iconTriangle";
import i18n from "../../../libs/i18n/i18n.js";
var InputDropdown = function (_a) {
    var selectedAction = _a.selectedAction, autoOpenOnResult = _a.autoOpenOnResult, results = _a.results, placeholder = _a.placeholder, _b = _a.multiple, multiple = _b === void 0 ? false : _b, _c = _a.agentId, agentId = _c === void 0 ? 0 : _c;
    var _d = useState(false), active = _d[0], setActive = _d[1];
    var _e = useState(null), value = _e[0], setValue = _e[1];
    var dropdown = useRef(null);
    useEffect(function () {
        if (multiple) {
            setValue(null);
        }
    }, [agentId]);
    useEffect(function () {
        if (autoOpenOnResult && results.length) {
            setActive(true);
        }
    }, [autoOpenOnResult, results]);
    useEffect(function () {
        // Bind the event listener
        if (active) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return function () {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [active]);
    var handleClick = function (result) {
        if (!result.isActive && typeof result.isActive !== 'undefined') {
            return;
        }
        selectedAction(result.value);
        setValue(result.name);
        setActive(false);
    };
    var handleClickOutside = function (event) {
        if (dropdown.current && !dropdown.current.contains(event.target)) {
            setActive(false);
        }
    };
    var List = function () {
        if (!results.length) {
            return React.createElement(React.Fragment, null);
        }
        return (React.createElement(React.Fragment, null, results.map(function (result) { return (React.createElement("div", { key: result.value, "data-value": result.value, onClick: function (e) { return handleClick(result); }, className: !result.isActive && typeof result.isActive !== "undefined" ? 'inactive' : '' }, !result.isActive && typeof result.isActive !== "undefined" ? (React.createElement("span", { className: "inactive" },
            result.name,
            " - Porteur inactif")) : (result.name))); })));
    };
    return (React.createElement("div", { className: "rdropdown", ref: dropdown },
        React.createElement("div", { className: "rdropdown__input-container" },
            React.createElement("input", { onClick: function (e) {
                    e.preventDefault();
                    setActive(!active);
                }, placeholder: i18n.t(placeholder), value: value ? value : "", readOnly: true }),
            React.createElement("div", { className: "rdropdown__icon", onClick: function () { return setActive(!active); } },
                React.createElement(IconTriangle, null))),
        React.createElement("div", { className: "rdropdown__list ".concat(active ? "active" : "") },
            React.createElement(List, null))));
};
export default InputDropdown;
