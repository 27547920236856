import React, {useState} from "react";

const Button = ({printAction, label, tagId, silentMode}) => {
  const [loading, setLoading] = useState(false);

  const print = async () => {
    setLoading(true);
    await printAction();
    setLoading(false);
  };

  return (
    <button
      className={`ui button ${silentMode ? 'blue' : 'black'} ${loading ? "loading" : ""}`}
      onClick={() => print()}
      disabled={loading}
      data-print={!silentMode ? `tag-print-${tagId}` : ''}
    >
      {label}
    </button>
  );
};

export default Button;
