import React, {useState, useEffect} from "react";
import DotationTransfer from "../../modules/dotationTransfer";
import AgentMutation from "../../modules/agentMutation";
import AgentDesactivation from "../../modules/agentDesactivation";
import AgentActivation from "../../modules/agentActivation";
import ModuleTagsPrinter from "../../modules/tagsPrinter";
import ModuleInactiveDotation from '../../modules/dotationDeactivate';
import ModuleReactiveDotation from '../../modules/dotationReactivate';

const ModalConductor = () => {
  const [modalType, setModalType] = useState("");
  const [data, setData] = useState(false);

  useEffect(() => {
    document.addEventListener(
      "onModalTrigger",
      function(e) {
        setData(e.detail.data);
        setModalType(e.detail.modalType);
      },
      false
    );

    document.addEventListener(
      "onReactPoppinClose",
      function() {
        setData(false);
        setModalType("");
        document.location.reload();
      },
      false
    );
  }, []);

  switch (modalType) {
    case "DOTATION_TRANSFER":
      return <DotationTransfer data={data} />;
    case "AGENT_MUTATION":
      return <AgentMutation data={data} />;
    case "AGENTS_MUTATION":
      return <AgentMutation data={data} multiple={true} />;
    case "AGENT_DESACTIVATION":
      return <AgentDesactivation data={data} />;
    case "AGENTS_DESACTIVATION":
      return <AgentDesactivation data={data} multiple={true} />;
    case "AGENT_ACTIVATION":
      return <AgentActivation data={data} />;
    case "TAGS_PRINTER":
      return <ModuleTagsPrinter data={data} />;
    case "INACTIVE_DOTATION":
      return <ModuleInactiveDotation data={data} />;
    case "REACTIVE_DOTATION":
      return <ModuleReactiveDotation data={data} />;
    default:
      return false;
  }
};

export default props => <ModalConductor {...props} />;
