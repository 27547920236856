import React from 'react';
import { TagActivityReason } from 'georges-types-kit';
import Col from '../../../grid/col';
import i18n from '../../../../libs/i18n/i18n';
import InputDropdown from '../../../shared/form/inputDropdown';
import Row from '../../../grid/row';
var ActivityReasonSelect = function (props) { return (React.createElement(Row, { className: "rform__row" },
    React.createElement(Col, { sm: 3 },
        i18n.t('tagActivity.reason.label'),
        ":"),
    React.createElement(Col, { sm: 7, column: true },
        React.createElement(InputDropdown, { results: [
                { value: TagActivityReason.CLIENT_FORM_REQUEST, name: i18n.t('tagActivity.reason.clientRequest') },
                { value: TagActivityReason.GEORGES_DECISION, name: i18n.t('tagActivity.reason.georgesDecision') },
            ], placeholder: "tagActivity.reason.placeholder", selectedAction: function (value) { return props.setReason(value); }, autoOpenOnResult: false })))); };
export default ActivityReasonSelect;
