import React, { useContext } from 'react';
import { TransferContext } from '../context/TransferProvider';
import { CLEAR_ALL } from '../context/TransferReducer';
import { transferTags } from '../context/TransferAction';
import useTagsLoading from '../hooks/useTagsLoading';
import Spinner from '../../../shared/spinner';
var FormActions = function () {
    var _a = useContext(TransferContext), state = _a[0], dispatch = _a[1];
    var _b = useTagsLoading(), isLoading = _b[0], setIsLoading = _b[1];
    var onSubmit = function () {
        setIsLoading(true);
        dispatch(transferTags());
    };
    return (React.createElement("div", { className: "transfer-actions" }, state.tags.length > 0 && (React.createElement(React.Fragment, null,
        React.createElement("button", { className: "button ui", onClick: function () { return dispatch({ type: CLEAR_ALL }); } }, "Tout annuler"),
        React.createElement("button", { className: "button ui button--blue", onClick: onSubmit }, "Transf\u00E9rer ".concat(state.tags.length, " v\u00EAtements"),
            isLoading && React.createElement(Spinner, null))))));
};
export default FormActions;
