import React, { useState, useEffect } from "react";

import i18n from "../../../libs/i18n/i18n";

const InputNumber = ({selectedAction, className, placeholder, agentId = 0}) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(false);
  }, [agentId]);

  return (
    <input
      value={value ? value : ''}
      className={className}
      type="number"
      placeholder={i18n.t(placeholder)}
      onChange={e => {
        selectedAction(e.target.value);
        setValue(e.target.value);
      }}
    />
  );
};

export default InputNumber;
