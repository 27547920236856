import React, { useContext } from 'react';
import i18n from '../../../../libs/i18n/i18n';
import { TransferContext } from '../context/TransferProvider';
import { CLEAR_ALL } from '../context/TransferReducer';
var ResultsActions = function () {
    var _a = useContext(TransferContext), dispatch = _a[1];
    return (React.createElement("div", { className: "transfer-summary--actions" },
        React.createElement("span", null, i18n.t('transfer.result.prod_information')),
        React.createElement("button", { className: "ui button button--blue", onClick: function () { return dispatch({ type: CLEAR_ALL }); } }, "Transf\u00E9rer d'autres v\u00EAtements")));
};
export default ResultsActions;
