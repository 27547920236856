import React, { createContext } from 'react';
import { initialState, transferReducer } from './TransferReducer';
import useMiddlewareReducer from '../hooks/useMiddlewareReducer';
export var TransferContext = createContext(null);
var TransferProvider = function (_a) {
    var children = _a.children;
    var _b = useMiddlewareReducer(transferReducer, initialState), state = _b[0], dispatch = _b[1];
    return (React.createElement(TransferContext.Provider, { value: [state, dispatch] }, children));
};
export default TransferProvider;
