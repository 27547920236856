import React, { useState, useEffect } from "react";

import i18n from "../../../libs/i18n/i18n";

const InputCheckbox = ({checkedAction, className, label, counter = 0}) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, [counter]);

  return (
    <label className={"rcheckbox"}>
      <input
        className={className}
        type="checkbox"
        onChange={e => {
          setChecked(e.target.checked);
          checkedAction(e.target.checked);
        }}
        checked={checked}
      />
      <span></span>
      {label ? i18n.t(label) : false}
    </label>
  );
};

export default InputCheckbox;
