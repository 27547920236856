import React, { useState } from "react";

import Container from "../../../grid/container";
import Row from "../../../grid/row";
import Col from "../../../grid/col";

import AgentSelection from "../../../shared/form/agentSelection";
import PlacesSelection from "../../../shared/form/placesSelection";
import InputNumber from "../../../shared/form/inputNumber";
import InputCheckbox from "../../../shared/form/inputCheckbox";
import InfoBox from "../../../shared/infoBox";
import Button from "../../../shared/button";
import InputDate from "../../../shared/form/inputDate";

import i18n from "../../../../libs/i18n/i18n";

const StepMain = ({
  setCurrentStep,
  data,
  setTransferDotation,
  transferDotation,
  clients,
  deliveryPlaceSelected,
  setDeliveryPlaceSelected,
  infrastructureSelected,
  setInfrastructureSelected,
  serviceSelected,
  setServiceSelected,
  setSelectedAgentId,
  selectedAgentId,
  setCasier,
  setArmoire,
  date,
  setDate,
  setAgent,
}) => {
  const [infoBox, setInfoBox] = useState(false);
  const [infoBoxProfession, setInfoBoxProfession] = useState(false);
  const [clientSelected, setClientSelected] = useState(false);

  return (
    <Container className="rform">
      <Row className="rform__row">
        <Col sm={3}>{i18n.t("agentToMutate")}:</Col>
        <Col sm={7} column={true}>
          <span className="rform__info rform__info--bold">
            {data.firstname} {data.name} - {data.registrationNumber}
          </span>
          <span className="rform__info">
            <p>
              Dotation: {data.tagsNumber} vêtements <br /> {data.clientName} -
              {data.infrastructure} - {data.service} - {data.deliveryPlace}
            </p>
          </span>
        </Col>
      </Row>

      {!data.gender && <Row className="rform__row">
        <Col sm={3}></Col>
        <Col sm={9}>
          <InfoBox infoBox={{ type: 'warning', message: 'noGender' }} />
        </Col>
      </Row>}

      <Row className="rform__row">
        <Col sm={3}>Date de reprise d'activité:</Col>
        <Col sm={9}>
          <InputDate date={date} setDate={setDate} />
        </Col>
      </Row>

      <Row className="rform__row">
        <Col sm={3}>
          <label htmlFor="agents">{i18n.t("newDeliveryPlace")}:</label>
        </Col>

        <Col sm={9} column={true}>
          <PlacesSelection
            data={data}
            clients={clients}
            clientSelected={clientSelected}
            setClientSelected={setClientSelected}
            setInfoBoxProfession={setInfoBoxProfession}
            deliveryPlaceSelected={deliveryPlaceSelected}
            setDeliveryPlaceSelected={setDeliveryPlaceSelected}
            infrastructureSelected={infrastructureSelected}
            setInfrastructureSelected={setInfrastructureSelected}
            serviceSelected={serviceSelected}
            setServiceSelected={setServiceSelected}
            setInfoBox={setInfoBox}
          />
        </Col>
      </Row>

      <Row className="rform__row">
        <Col sm={3}></Col>
        <Col sm={9}>
          <InfoBox infoBox={infoBoxProfession} />
        </Col>
      </Row>

      <Row className="rform__row">
        <Col sm={3}>{i18n.t("newCasier")}:</Col>
        <Col sm={9}>
          <Row>
            <Col sm={3}>
              <InputNumber
                placeholder={"armoire"}
                selectedAction={value => setArmoire(value)}
              />
            </Col>
            <Col sm={3}>
              <InputNumber
                placeholder={"casier"}
                selectedAction={value => setCasier(value)}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      {data.tagsNumber ? (
        <>
          <Row className="rform__row">
            <Col sm={3}>{i18n.t("dotationTransfert")}:</Col>
            <Col sm={9}>
              <InputCheckbox
                checkedAction={checked => setTransferDotation(checked)}
                label={"transferDotationsWithAgent"}
              />
            </Col>
          </Row>

          <AgentSelection
            setSelectedAgentId={setSelectedAgentId}
            selectedAgentId={selectedAgentId}
            agentId={data.id}
            setAgent={setAgent}
            display={transferDotation}
          />
        </>
      ) : (
        false
      )}

      {!transferDotation ? (
        <Row className="rform__row">
          <Col sm={3}></Col>
          <Col sm={9}>
            <InfoBox infoBox={infoBox} />
          </Col>
        </Row>
      ) : (
        false
      )}

      <Row className="justify-content-center">
        <Col sm={4}>
          <Button
            color={"red"}
            tradKey={"cancel"}
            onClick={() => document.dispatchEvent(new CustomEvent("onReactPoppinClose"))}
            show={true}
          />
        </Col>
        <Col sm={4}>
          <Button
            color={"green"}
            tradKey={"validate"}
            onClick={() => setCurrentStep("loading")}
            show={
              infrastructureSelected &&
              serviceSelected &&
              deliveryPlaceSelected &&
              clientSelected
            }
          />
        </Col>
      </Row>
    </Container>
  );
};

export default StepMain;
