import React, {useEffect, useRef} from "react";

import Container from "../../../grid/container";
import Row from "../../../grid/row";
import Col from "../../../grid/col";

import i18n from "../../../../libs/i18n/i18n";
import Loading from "../../../shared/loading";
import {checkClothInCatalogByAgent, setTagAgent} from "../../../../api/dotationTransfer";
import DotationInformationLoading from '../../../shared/modal/DotationInformationLoading';

const StepLoading = ({setCurrentStep, selectedAgentId, tag, setErrorMessage, agent}) => {
  useEffect(() => {
    const check = async () => {
      try {
        const isInCatalog = await checkClothInCatalogByAgent(selectedAgentId, [
          tag.clothId,
        ]);

        if (isInCatalog.success) {
          await setTagAgent([tag.id], selectedAgentId);

          setCurrentStep("success");
        } else {
          setErrorMessage(
            i18n.t("transferError", {
              clothsNotFound: isInCatalog.clothsNotFound,
              catalogs: isInCatalog.catalogs,
              infrastructure: agent.infrastructure.name,
            })
          );
          setCurrentStep("error");
        }
      } catch (e) {
        console.log("Erreur lors de la vérification du vêtement dans le catalogue: ", e);
        setErrorMessage(
          i18n.t("transferError", {
            clothsNotFound: isInCatalog.clothsNotFound,
            catalogs: isInCatalog.catalogs,
            infrastructure: agent.infrastructure.name,
          })
        );
        setCurrentStep("error");
      }
    };

    window.setTimeout(() => check(), 1000);
  }, []);

  return (
    <DotationInformationLoading data={tag} label="clothToTransfer" />
  );
};

export default StepLoading;
