import React from 'react';
import Container from '../../../grid/container';
import DotationInformation from '../../../shared/modal/dotationInformation';
import Row from '../../../grid/row';
import Col from '../../../grid/col';
import Button from '../../../shared/button';
import { STEP } from '../../../../types/modalTypes';
import ReactivationRule from './ReactivationRule';
import TransferScheme from '../../../shared/transferScheme';
import AgentSelect from './AgentSelect';
var StepMain = function (_a) {
    var data = _a.data, setCurrentStep = _a.setCurrentStep, agent = _a.agent, setAgent = _a.setAgent;
    return (React.createElement(Container, { className: "rform" },
        React.createElement(DotationInformation, { data: data, label: "dotationToReactive" }),
        React.createElement(ReactivationRule, { rule: data.reactivationRule }),
        React.createElement(AgentSelect, { originalAgent: {
                id: data.agentId,
                firstname: data.agentFirstname,
                name: data.agentName,
                registrationNumber: data.agentRegistrationNumber,
                active: data.agentIsActive,
                deliveryPlace: {
                    code: data.deliveryPlaceCode,
                    washingCenter: {
                        name: data.washingCenter
                    },
                },
            }, setNewAgent: setAgent, newAgent: agent, rule: data.reactivationRule, infrastructureId: data.infrastructureId }),
        React.createElement(TransferScheme, { originalAgent: "".concat(data.agentFirstname, " ").concat(data.agentName, " ").concat(data.agentRegistrationNumber), recipientAgent: agent ? "".concat(agent.firstname, " ").concat(agent.name, " ").concat(agent.registrationNumber) : null, transferDotation: true }),
        React.createElement(Row, { className: "justify-content-center" },
            React.createElement(Col, { sm: 5 },
                React.createElement(Button, { color: "red", tradKey: "cancel", onClick: function () { return document.dispatchEvent(new CustomEvent('onReactPoppinClose')); }, show: true })),
            React.createElement(Col, { sm: 5 },
                React.createElement(Button, { color: "green", tradKey: "validate", onClick: function () { return setCurrentStep(STEP.LOADING); }, show: agent !== null })))));
};
export default StepMain;
