import { useState } from 'react';
import { ControlEventType } from 'georges-types-kit';
import { createControlEvent, sendRepairEstimate } from '../../../api/control';
var useControlTag = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(null), error = _b[0], setError = _b[1];
    var _c = useState(false), estimateSent = _c[0], setEstimateSent = _c[1];
    var getStatus = function (status) {
        switch (status) {
            case ControlEventType.IN_PROGRESS:
                return 'En cours';
            case ControlEventType.REFORM:
                return 'Réforme proposée';
            case ControlEventType.REPAIRS:
                return 'Réparation(s) proposée(s)';
            case ControlEventType.WAITING_CLIENT_APPROVAL:
                return 'Réparations en attente d\'approbation du client';
            case ControlEventType.SUCCESS:
                return 'OK';
            default:
                return '';
        }
    };
    var handleNewControl = function (tagId) {
        setLoading(true);
        createControlEvent(tagId)
            .then(function (data) {
            if (!data.success) {
                throw new Error("Une erreur est survenue : ".concat(data.response));
            }
            // Dispatch event to script.js, to refresh view.
            document.dispatchEvent(new CustomEvent('postControlDone', {
                detail: { data: data.response }
            }));
            setLoading(false);
        })
            .catch(function (error) {
            setError(error);
        });
    };
    var handleSendEstimate = function (repairEstimate) {
        setLoading(true);
        sendRepairEstimate(repairEstimate.id)
            .then(function (data) {
            if (!data.success) {
                throw new Error("Une erreur est survenue : ".concat(data.response));
            }
            setEstimateSent(true);
            setLoading(false);
            document.dispatchEvent(new CustomEvent('repairEstimateSent', {
                detail: { data: data.response }
            }));
        })
            .catch(function (error) {
            setError(error);
        });
    };
    return {
        loading: loading,
        error: error,
        getStatus: getStatus,
        handleNewControl: handleNewControl,
        handleSendEstimate: handleSendEstimate,
        estimateSent: estimateSent,
        setEstimateSent: setEstimateSent,
    };
};
export default useControlTag;
