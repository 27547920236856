import React from "react";

import Row from "../grid/row";
import Col from "../grid/col";
import logo from "../../../img/loading.png";

const Loading = () => {
  return (
    <Row>
      <Col sm={12}>
        <div className="logo_loading" style={{justifyContent: "center", display: "flex"}}>
          <img src={logo} alt="logo-loading" />
        </div>
      </Col>
    </Row>
  );
};

export default Loading;
