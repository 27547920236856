import React from 'react';
import Row from '../../grid/row';
import Col from '../../grid/col';
import i18n from '../../../libs/i18n/i18n';
import Container from '../../grid/container';
import Loading from '../loading';
var DotationInformationLoading = function (_a) {
    var data = _a.data, label = _a.label;
    return (React.createElement("div", { className: "loading rpoppin__loading" },
        React.createElement(Container, { className: "" },
            React.createElement(Row, { className: "justify-content-center" },
                React.createElement(Col, { sm: 8 },
                    React.createElement("div", { className: "rpoppin__loading__title" },
                        i18n.t(label),
                        "V\u00EAtement \u00E0 d\u00E9sactiver:",
                        " ",
                        React.createElement("span", null,
                            data.clothReference,
                            " - ",
                            data.agentName,
                            " ",
                            data.agentFirstname,
                            " -",
                            " ",
                            data.agentRegistrationNumber)),
                    React.createElement("p", null, "En cours..."))),
            React.createElement(Loading, null))));
};
export default DotationInformationLoading;
