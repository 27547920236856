var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var SET_TAG_DATA = 'SET_TAG_DATA';
export var SET_CAN_EDIT = 'SET_CAN_EDIT';
export var SET_IS_BLOCKED_BY_REPAIRS = 'SET_IS_BLOCKED_BY_REPAIRS';
export var REFORM_ASKED = 'REFORM_ASKED';
export var initialValue = {
    tagData: null,
    canEdit: false,
    isBlockedByRepairs: false,
};
var AskReformReducer = function (state, action) {
    switch (action.type) {
        case SET_TAG_DATA:
            return __assign(__assign({}, state), { tagData: action.payload });
        case SET_CAN_EDIT:
            return __assign(__assign({}, state), { canEdit: action.payload });
        case SET_IS_BLOCKED_BY_REPAIRS:
            return __assign(__assign({}, state), { isBlockedByRepairs: action.payload });
        case REFORM_ASKED:
            return __assign(__assign({}, state), { canEdit: false, tagData: __assign(__assign({}, state.tagData), { waitingReformation: true }) });
        default:
            return state;
    }
};
export default AskReformReducer;
