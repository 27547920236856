import React from 'react';
import { TagActivityRule } from 'georges-types-kit';
import Row from '../../../grid/row';
import Col from '../../../grid/col';
import i18n from '../../../../libs/i18n/i18n';
import InputDropdown from '../../../shared/form/inputDropdown';
var ActivityRuleSelect = function (props) { return (React.createElement(Row, { className: "rform__row" },
    React.createElement(Col, { sm: 3 },
        i18n.t('tagActivity.reactivationRule.label'),
        ":"),
    React.createElement(Col, { sm: 7, column: true },
        React.createElement(InputDropdown, { results: [
                { value: TagActivityRule.BACK_TO_AGENT, name: i18n.t('tagActivity.reactivationRule.backToAgent') },
                { value: TagActivityRule.BACK_TO_STOCK, name: i18n.t('tagActivity.reactivationRule.backToStock') },
            ], placeholder: "tagActivity.reactivationRule.placeholder", selectedAction: function (value) { return props.setRule(value); }, autoOpenOnResult: false })))); };
export default ActivityRuleSelect;
