import React, {useState, useEffect, createRef} from "react";
import { getNotificationsCounter, patchNotifications } from '../../../api/userNotifications'
import NotificationsList from "./NotificationsList";
import { BellOutlined } from '@ant-design/icons';
import { Badge } from 'antd';

const useOnClickOutside = (ref, handler) => {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because the passed-in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler],
    );
  };

const HeaderNotifications = ({data}) => {
    const [showNotifCenter, setShowNotifCenter] = useState(false);
    const [badge, setBadge] = useState(0);
    const userId = data.userId;
    const ref = createRef();

    const clearNotifCenter = () => {
        if (showNotifCenter) {
          patchNotifications();
        }
    };

    useOnClickOutside(ref, () => {
        clearNotifCenter();
        setShowNotifCenter(false);
    });    

    useEffect(() => {
        if (userId) {
            const eventSource = new EventSource(
                `${data.mercureUrl}?topic=georges/issueAlert/${userId}`,
            );
        
            const call = () => {
                getNotificationsCounter().then((response) => {
                    setBadge(response);
                });
            };
        
            eventSource.onmessage = (event) => {
                call();
            };
    
            call();
        }
    }, []);

    return (
        <div className="header__notif" ref={ref}>
            {userId && (
                <Badge count={badge}>
                    <div className="header__icon bell">
                        <BellOutlined
                            onClick={() => {
                                setShowNotifCenter(true);
                                clearNotifCenter();
                            }}
                        />
                    </div>
                </Badge>
            )}
            {showNotifCenter && (
                <NotificationsList />
            )}
        </div>
    );
};

export default props => <HeaderNotifications {...props} />;
