import {call} from "./apiCall";

/**
 * Check cloth transfer rules
 * @param {int} agentId
 * @param {int} selectedAgentId
 * @param {int} selectedServiceId
 */
export const checkTransferRules = async (
  agentId,
  selectedAgentId,
  selectedServiceId = false
) => {
  const response = await call(
    `/v1/admin/helpers/check_transfer_rules`,
    "POST",
    `currentAgentId=${agentId}&newAgentId=${selectedAgentId}&newServiceId=${selectedServiceId}`
  );

  return response;
};

/**
 * Check agent's profession is in target infrastructure's professions.
 */
export const checkTransferProfession = async (agentId, infrastructureId) => {
  return call(
    `/v1/admin/helpers/mutation-profession?agent=${agentId}&infrastructure=${infrastructureId}`,
    "GET",
  );
};

/**
 * Search agents by name
 */
export const searchAgents = async (slug) => {
  const response = await call(
    `/v1/admin/agent/by_name`,
    "POST",
    `q=${slug}`
  );

  return response.results;
};

export const searchAllAgents = async (search) => {
  const response = await call(`/v1/admin/agent/search?q=${search}`);

  return 'data' in response ? response.data : [];
}

/**
 * Check if cloth is part of the catalog of a given agent
 * @param {int} selectedAgentId
 * @param {int} clothIds
 */
export const checkClothInCatalogByAgent = async (selectedAgentId, clothIds) => {
  const response = await call(
    `/v1/admin/cloth/check_cloth_in_catalog_by_agent/${selectedAgentId}`,
    "POST",
    `clothIds=${JSON.stringify(clothIds)}`
  );

  return response;
};

/**
 * Check if cloth is part of the catalog of a given infrastructure
 * @param {int} selectedInfrastructureId
 * @param {int} clothIds
 */
export const checkClothInCatalogByInfrastructure = async (
  selectedInfrastructureId,
  clothIds
) => {
  const response = await call(
    `/v1/admin/cloth/check_cloth_in_catalog_by_infrastructure/${selectedInfrastructureId}`,
    "POST",
    `clothIds=${JSON.stringify(clothIds)}`
  );

  return response;
};

/**
 * Create new association between agent and tag
 * @param {Array} tagIds
 * @param {int} selectedAgentId
 */
export const setTagAgent = async (tagIds, selectedAgentId) => {
  const createResponse = await call(
    `/v1/admin/tag/set_tag_agent/${selectedAgentId}`,
    "POST",
    `tagIds=${JSON.stringify(tagIds)}`
  );

  return createResponse;
};
