import React, {useState, useEffect} from "react";

import StepMain from "./components/stepMain";
import StepLoading from "./components/stepLoading";
import StepResult from "../../shared/modal/DotationStepResult";
import ModalWrapper from "./../../shared/modal/modalWrapper";

import i18n from "../../../libs/i18n/i18n";

const ModuleDotationTransfer = ({data}) => {
  const [currentStep, setCurrentStep] = useState("start");
  const [selectedAgentId, setSelectedAgentId] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalHeight, setModalHeight] = useState("auto");
  const [agent, setAgent] = useState(false);

  const steps = {
    start: (
      <StepMain
        setCurrentStep={setCurrentStep}
        tag={data}
        selectedAgentId={selectedAgentId}
        setSelectedAgentId={setSelectedAgentId}
        agent={agent}
        setAgent={setAgent}
      />
    ),
    loading: (
      <StepLoading
        setCurrentStep={setCurrentStep}
        selectedAgentId={selectedAgentId}
        tag={data}
        setErrorMessage={setErrorMessage}
        agent={agent}
      />
    ),
    success: (
      <StepResult
        data={data}
        isSuccess={true}
        message={i18n.t("transferSuccess")}
        setCurrentStep={setCurrentStep}
      />
    ),
    error: (
      <StepResult
        data={data}
        isSuccess={false}
        message={errorMessage}
        setCurrentStep={setCurrentStep}
      />
    ),
  };

  return (
    <ModalWrapper
      modalHeight={modalHeight}
      setModalHeight={setModalHeight}
      currentStep={currentStep}
      modalTitle={"transferCloth"}
    >
      {steps[currentStep]}
    </ModalWrapper>
  );
};

export default props => <ModuleDotationTransfer {...props} />;
