import React, { useState } from 'react';
import { ENTER_KEY, TAG_LENGTH } from '../../modules/TransferTags/config';
import Spinner from '../spinner';
var InputScan = function (_a) {
    var action = _a.action, inputRef = _a.inputRef, isLoading = _a.isLoading;
    var _b = useState(''), value = _b[0], setValue = _b[1];
    var _c = useState(false), isFocus = _c[0], setIsFocus = _c[1];
    var handleChange = function (event) {
        var inputValue = event.currentTarget.value.trim();
        setValue(inputValue);
        if (inputValue.trim().length === TAG_LENGTH) {
            action(inputValue.trim());
            setTimeout(function () { return setValue(''); }, 500);
        }
    };
    var handleButtonClick = function () {
        action(value.trim());
        inputRef.current.focus();
    };
    return (React.createElement("div", { className: "field" },
        React.createElement("div", { className: "ui fluid action input" },
            React.createElement("input", { ref: inputRef, type: "text", placeholder: "Code v\u00EAtement", id: "scan-transfer-input", className: "scan-input", value: value, onChange: handleChange, onKeyPress: function (e) {
                    // Avoid double action when code is entered by scan, because scan trigger onChange and onePress with Enter Key.
                    if (value.length !== TAG_LENGTH && e.key === ENTER_KEY) {
                        e.key === ENTER_KEY && handleButtonClick();
                    }
                }, onFocus: function () { return setIsFocus(true); }, onBlur: function () { return setIsFocus(false); } }),
            React.createElement("div", { className: "ui black button scan-input-button ".concat(isFocus ? 'focused' : ''), id: "scan-transfer-input-button", onClick: handleButtonClick }, isLoading ? React.createElement(Spinner, { classes: "scan-spinner" }) : 'Scan'))));
};
export default InputScan;
