import React from "react";

export default ({color}) => (
  <svg
    width="45px"
    height="45px"
    viewBox="0 0 45 45"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Icons/Menu/Home_off"
      stroke="none"
      stroke-width="1"
      fill={color}
      fill-rule="evenodd"
    >
      <path
        d="M28.25,27.7989624 C28.25,28.2347489 27.858,28.4210634 27.375,28.4210634 L26.5,28.4210634 C26.017,28.4210634 25.625,28.2347489 25.625,27.7989624 L25.625,27.0094941 C25.625,26.1371317 24.8418746,25.2631903 23.875,25.2631903 L22.125,25.2631903 C21.1581254,25.2631903 20.375,26.1371317 20.375,27.0094941 L20.375,27.7989624 C20.375,28.2347489 19.983,28.4210634 19.5,28.4210634 L18.625,28.4210634 C18.142,28.4210634 17.75,28.2347489 17.75,27.7989624 L17.75,21.4334796 C17.75,21.3284803 17.7963746,21.2282178 17.87775,21.1540078 L22.3726254,17.0992987 C22.71475,16.7906166 23.2686254,16.7906166 23.6098754,17.0992987 L28.12225,21.1697972 C28.2036254,21.2440072 28.25,21.3442697 28.25,21.4484795 L28.25,27.7989624 Z M30,21.0197982 C30,20.8105891 29.9081254,20.6108536 29.7453746,20.4624336 L24.23375,15.4650993 C23.55125,14.8469457 22.4426254,14.8445773 21.7575,15.4603625 L16.25725,20.4063814 C16.09275,20.5548014 16,20.7553263 16,20.9653249 L16,28.5884307 C16,29.4607932 16.7831254,30 17.75,30 L20.375,30 C21.3418746,30 22.125,29.4607932 22.125,28.5884307 L22.125,27.7989624 C22.125,27.3631759 22.517,27.0094941 23,27.0094941 C23.483,27.0094941 23.875,27.3631759 23.875,27.7989624 L23.875,28.5884307 C23.875,29.4607932 24.6581254,30 25.625,30 L28.25,30 C29.2168746,30 30,29.4607932 30,28.5884307 L30,21.0197982 Z"
        id="home-[#1393]"
        fill={color}
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
);
