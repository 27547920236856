import React, {useEffect, useRef} from "react";

import Container from "../../../grid/container";
import Row from "../../../grid/row";
import Col from "../../../grid/col";

import Button from "../../../shared/button";
import Success from "../../../../../svg/success.js";
import Error from "../../../../../svg/error.js";

const StepResult = ({message, isSuccess, data}) => {
  const container = useRef(null);

  const ResultImage = () => {
    if (isSuccess) {
      return <Success fill={"#55D2BE"} />;
    }

    return <Error />;
  };

  return (
    <div className="rpoppin__result" ref={container}>
      <Container>
        <Row className={"justify-content-center"}>
          <Col sm={8}>
            <div className="rpoppin__result__title">
              Agent à désactiver:
              <span>
                {data.name} {data.firstname} - {data.registrationNumber}
              </span>
            </div>
            <div
              className={`rpoppin__result__content rpoppin__result__content--${
                isSuccess ? "green" : "red"
              }`}
            >
              <div className="rpoppin__result__image">
                <ResultImage />
              </div>
              <p>{message}</p>
            </div>
          </Col>
        </Row>

        <Row className={"justify-content-center"}>
          <Col sm={4}>
            <Button
              color={isSuccess ? "green" : "red"}
              onClick={() =>
                document.dispatchEvent(new CustomEvent("onReactPoppinClose"))
              }
              tradKey={"ok"}
              show={true}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StepResult;
