export const call = async (
  uri,
  method,
  body = undefined,
  encType = "application/x-www-form-urlencoded"
) => {
  const accessToken = localStorage.getItem("access_token");
  // const refreshToken = localStorage.getItem("refresh_token");
  const headersOptions = {
    Authorization: "Bearer " + accessToken,
  };

  if (encType != "multipart/form-data") {
    headersOptions["Content-Type"] = encType;
  }

  const headers = new Headers(headersOptions);
  const response = await fetch(`${API_URL}${uri}`, {
    method,
    headers,
    body,
  });

  return await response.json();
};
