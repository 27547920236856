import React, { useContext } from 'react';
import { AskReformContext } from '../context/AskReformProvider';
var ReformReasonList = function (props) {
    var canEdit = useContext(AskReformContext)[0].canEdit;
    var reasons = props.reasons, selectedReasonsIds = props.selectedReasonsIds, switchReason = props.switchReason;
    return (React.createElement("div", { className: "ask-reform--list" }, reasons.map(function (reason) { return (React.createElement("div", { className: "ui toggle checkbox ask-reform--list-item ".concat(!canEdit && 'disabled'), key: reason.id },
        React.createElement("input", { type: "checkbox", name: "reform-reason", id: "reason-".concat(reason.id), checked: selectedReasonsIds.includes(reason.id), onChange: function () { return switchReason(reason.id); }, disabled: !canEdit }),
        React.createElement("label", { htmlFor: "reason-".concat(reason.id) }, reason.short_description))); })));
};
export default ReformReasonList;
