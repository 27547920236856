import React, { useContext, useEffect } from 'react';
import { TransferContext } from '../context/TransferProvider';
import TransferSummary from './TransferSummary';
import ResultsActions from './ResultsActions';
import ModuleTagsPrinter from '../../tagsPrinter';
var Results = function () {
    var state = useContext(TransferContext)[0];
    /**
     * Focus prod input when transfer is done.
     * TODO: Remove this React anti-pattern when all prod will be in React.
     */
    useEffect(function () {
        if (state.success !== null) {
            document.getElementById('scan-input').focus();
        }
    }, [state.success]);
    if (null === state.success) {
        return null;
    }
    var canPrint = function () { return (state.success === true && state.printer !== null && state.batchId !== null); };
    return (React.createElement(React.Fragment, null,
        React.createElement(TransferSummary, null),
        canPrint() && (React.createElement("div", { className: "transfer-summary--print" },
            React.createElement(ModuleTagsPrinter, { data: {
                    printer: state.printer,
                    url: "/v1/admin/tag/get_tags_in_batch/".concat(state.batchId)
                } }))),
        React.createElement(ResultsActions, null)));
};
export default Results;
