import React from "react";

const Card = ({tag, handleTagLoaded}) => {
  return (
    <div className={`print-container__card`}>
      <h2>{tag.code}</h2>
      <iframe
        src={`${API_URL}/tags/print/${tag.code}?mode=textile`}
        className="tag"
        name={`tag-print-${tag.id}`}
        frameBorder="0"
        scrolling="no"
        onLoad={() => document.dispatchEvent(new CustomEvent("onPoppinContentChange"))}
        width={tag.modelType == 1 ? "200" : ""}
        style={{height: "150px"}}
      ></iframe>
    </div>
  );
};

export default Card;
