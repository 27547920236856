import React from "react";

export default ({color}) => (
  <svg
    width="60px"
    height="59px"
    viewBox="0 0 60 59"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="Menu"
        transform="translate(-15.000000, -40.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="Logo_orange_gris_RVB" transform="translate(15.000000, 40.000000)">
          <path
            d="M47.2215998,10.1196172 L43.9067074,5.52631579 L40.910296,30.2287679 C40.910296,36.313149 35.9779306,41.2455144 29.8935496,41.2455144 C23.8091685,41.2455144 18.8768031,36.313149 18.8768031,30.2287679 L15.8489921,5.54874402 L12.2918749,10.1196172 L3.19947061,14.1118421 C1.14953041,14.8519737 -0.447359537,16.8884569 0.113346204,19.0909091 L7.81520027,51.9078947 C8.68541558,55.8238636 11.619028,58.9055024 15.6247098,58.9055024 L44.1623893,58.9055024 C48.1680711,58.9055024 51.0029993,56.0122608 51.9718988,51.9078947 L59.6692673,19.0909091 C60.1178319,16.9063995 58.6241118,14.860945 56.5607146,14.1297847 L47.2215998,10.1196172 Z"
            id="Path"
          ></path>
          <path
            d="M30.1749402,13.1339713 C29.2841314,13.1339713 28.4810375,13.6705815 28.1401397,14.4935815 C27.799242,15.3165815 27.9876744,16.2638954 28.6175713,16.8937923 C29.2474682,17.5236892 30.1947822,17.7121217 31.0177821,17.3712239 C31.8407821,17.0303262 32.3773923,16.2272322 32.3773923,15.3364234 C32.380656,15.1302082 32.3534487,14.9246415 32.2966507,14.7263756 C32.0300223,13.7851022 31.1711901,13.1350267 30.1928828,13.1339713"
            id="Path"
          ></path>
          <path
            d="M30.1749402,29.3854665 C31.3913209,29.3854665 32.3773923,28.3993951 32.3773923,27.1830144 C32.3773923,25.9666336 31.3913209,24.9805622 30.1749402,24.9805622 C28.9585595,24.9805622 27.972488,25.9666336 27.972488,27.1830144 C27.972488,28.3993951 28.9585595,29.3854665 30.1749402,29.3854665"
            id="Path"
          ></path>
          <path
            d="M38.9982057,0.349880383 C38.1010766,0.497906699 34.0639952,1.76285885 32.5568182,2.9201555 C32.1970422,2.41683563 31.6144712,2.12052796 30.9958134,2.12619617 L29.354067,2.12619617 C28.7417326,2.12635817 28.1671141,2.42201945 27.8110048,2.9201555 C26.2769139,1.76285885 22.2622608,0.497906699 21.3516746,0.349880383 C20.8576394,0.295075052 20.3636151,0.453018823 19.993002,0.784259584 C19.622389,1.11550035 19.4101829,1.58876013 19.40939,2.08582536 L19.40939,8.46441388 C19.4282091,9.39433903 20.1545288,10.155709 21.0825359,10.2183014 C21.1668191,10.2195548 21.2510041,10.2120383 21.3337321,10.1958732 C22.2308612,10.0523325 26.2679426,8.78289474 27.7840909,7.62559809 C28.145453,8.12064501 28.7232395,8.41120815 29.3361244,8.40610048 L30.9958134,8.40610048 C31.6086983,8.41120815 32.1864848,8.12064501 32.5478469,7.62559809 C34.0595096,8.78289474 38.083134,10.0523325 38.9982057,10.2003589 C39.0803138,10.2068676 39.1628082,10.2068676 39.2449163,10.2003589 C39.7091649,10.1990915 40.1504109,9.99807948 40.4560407,9.6486244 C40.7600349,9.32158546 40.9311691,8.8929504 40.9360179,8.44647129 L40.9360179,2.08582536 C40.9379309,1.58869119 40.7271725,1.11446251 40.3568866,0.782748082 C39.9866008,0.451033653 39.4921363,0.293500189 38.9982057,0.349880383 M28.1833134,4.72787081 C28.1833134,4.64712919 28.1833134,4.56638756 28.1833134,4.49013158 C28.1983878,4.2334837 28.278669,3.98492076 28.416567,3.76794258 C28.5317286,3.59950446 28.6855353,3.46107849 28.8651316,3.36423445 L29.0490431,3.27900718 C29.7936603,3.00089713 31.8256579,2.96052632 31.8794856,4.09539474 L31.8794856,4.12230861 C31.3848435,3.93958718 30.8587549,3.85724287 30.3319378,3.88008373 C29.4535445,3.86445799 28.6312633,4.31049875 28.1653708,5.05532297 C28.1653708,4.97906699 28.1653708,4.902811 28.1653708,4.88486842 C28.1653708,4.86692584 28.1653708,4.78169856 28.1653708,4.72787081"
            id="Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);
