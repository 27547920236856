import React from "react";

export default ({ color = '#FFFFFF' }) => (
  <svg
    width="11px"
    height="10px"
    viewBox="0 0 11 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Icon---Triangle-(Dropdown)"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <polygon
        id="Triangle"
        fill={color}
        transform="translate(5.500000, 5.000000) scale(1, -1) translate(-5.500000, -5.000000) "
        points="5.5 0 11 10 0 10"
      ></polygon>
    </g>
  </svg>
);
