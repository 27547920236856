import { useContext, useEffect, useState } from 'react';
import { TransferContext } from '../context/TransferProvider';
/**
 * Handle loading state for tags loading.
 */
var useTagsLoading = function () {
    var state = useContext(TransferContext)[0];
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    /**
     * Remove loading when state has changed after action.
     */
    useEffect(function () {
        setIsLoading(false);
    }, [state.tags, state.modal, state.success]);
    return [isLoading, setIsLoading];
};
export default useTagsLoading;
