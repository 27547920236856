import React, { useContext, useEffect, useRef } from 'react';
import InputScan from '../../../shared/form/InputScan';
import { TransferContext } from '../context/TransferProvider';
import { scanToTransfer } from '../context/TransferAction';
import useTagsLoading from '../hooks/useTagsLoading';
var TransferScan = function () {
    var _a = useContext(TransferContext), state = _a[0], dispatch = _a[1];
    var input = useRef(null);
    var _b = useTagsLoading(), isLoading = _b[0], setIsLoading = _b[1];
    var onScan = function (code) {
        setIsLoading(true);
        dispatch(scanToTransfer(code));
    };
    useEffect(function () {
        if (state.agent !== null && input) {
            if (state.modal === null) {
                input.current.focus();
            }
            else {
                input.current.blur();
            }
        }
    }, [state.agent, input, state.modal]);
    return state.agent !== null
        ? React.createElement(InputScan, { action: onScan, inputRef: input, isLoading: isLoading })
        : null;
};
export default TransferScan;
