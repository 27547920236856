import { useEffect, useState } from 'react';
/**
 * Hook to handle component display depending on custom events send from VanillaJS (script.js).
 * @param displayEvent
 * @param hideEvent
 * @param onDisplay
 * @param onHide
 */
var useEventDisplay = function (displayEvent, hideEvent, onDisplay, onHide) {
    var _a = useState(false), show = _a[0], setShow = _a[1];
    /**
     * Custom Event listener.
     */
    useEffect(function () {
        document.addEventListener(displayEvent, function (e) {
            onDisplay !== undefined && onDisplay(e);
            setShow(true);
        });
        if (hideEvent) {
            document.addEventListener(hideEvent, function () {
                setShow(false);
                onHide !== undefined && onHide();
            });
        }
        return function () {
            document.removeEventListener(displayEvent, function (e) { return onDisplay !== undefined && onDisplay(e.detail); });
            if (hideEvent) {
                document.removeEventListener(hideEvent, function () { return setShow(false); });
            }
            setShow(false);
        };
    }, []);
    return show;
};
export default useEventDisplay;
