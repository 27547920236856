import React from "react";

export default () =>
    <svg width="61px" height="61px" viewBox="0 0 61 61" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
        <desc>Created with Sketch.</desc>
        <g id="Error" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="no-entry" fill="#FD6871" fillRule="nonzero">
                <path d="M30.5,5 C16.4168945,5 5,16.4168945 5,30.5 C5,44.5831055 16.4168945,56 30.5,56 C44.5831055,56 56,44.5831055 56,30.5 C55.9833984,16.4235352 44.5764648,5.01660156 30.5,5 Z M50.0238095,36 L10.9761905,36 C10.4369977,36 10,35.5523438 10,35 L10,27 C10,26.4476562 10.4369977,26 10.9761905,26 L50.0238095,26 C50.5630023,26 51,26.4476562 51,27 L51,35 C51,35.5523438 50.5630023,36 50.0238095,36 Z" id="Shape"></path>
                <path d="M30.5,0 C13.6552176,0 0,13.6552176 0,30.5 C0,47.3447824 13.6552176,61 30.5,61 C47.3447824,61 61,47.3447824 61,30.5 C60.9808524,13.6633022 47.3366978,0.0191475732 30.5,0 Z M30.5,58.8214286 C14.8585379,58.8214286 2.17857143,46.1414621 2.17857143,30.5 C2.17857143,14.8585379 14.8585379,2.17857143 30.5,2.17857143 C46.1414621,2.17857143 58.8214286,14.8585379 58.8214286,30.5 C58.8035575,46.1338031 46.1338031,58.8035575 30.5,58.8214286 Z" id="Shape"></path>
            </g>
        </g>
    </svg>