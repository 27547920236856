import {call} from "./apiCall";

/**
 * Desactivate an agent
 * @param {string} query
 */
export const searchAgent = async queries => {
  const searchAgent = await call(
    `/v1/admin/agent/search_agent?${queries.map(query => `q[]=${query}&`)}`,
    "POST"
  );

  return searchAgent;
};
