import React, { useContext } from 'react';
import TagsToTransfer from './TagsToTransfer';
import TransferScan from './TransferScan';
import AgentBlock from './AgentBlock';
import FormActions from './FormActions';
import { TransferContext } from '../context/TransferProvider';
import TagsTotal from './TagsTotal';
var TransferForm = function (props) {
    var state = useContext(TransferContext)[0];
    if (state.success !== null) {
        return null;
    }
    return (React.createElement("div", { className: "transfer-tags-container" },
        React.createElement(TagsToTransfer, null),
        React.createElement("div", { className: "transfer-form-actions" },
            React.createElement(TagsTotal, null),
            React.createElement(TransferScan, null),
            React.createElement(AgentBlock, null),
            React.createElement(FormActions, null))));
};
export default TransferForm;
