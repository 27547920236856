import React, { useRef } from "react";
import Container from "../../grid/container";
import Row from "../../grid/row";
import Col from "../../grid/col";
import Button from "../button";
import Success from "../../../../svg/success.js";
import Error from "../../../../svg/error.js";
var StepResult = function (_a) {
    var message = _a.message, isSuccess = _a.isSuccess, data = _a.data;
    var container = useRef(null);
    return (React.createElement("div", { className: "rpoppin__result", ref: container },
        React.createElement(Container, { className: "" },
            React.createElement(Row, { className: "justify-content-center" },
                React.createElement(Col, { sm: 8 },
                    React.createElement("div", { className: "rpoppin__result__title" },
                        "V\u00EAtement \u00E0 transmettre:",
                        " ",
                        React.createElement("span", null,
                            data.clothReference,
                            " - ",
                            data.agentName,
                            " ",
                            data.agentFirstname,
                            " -",
                            " ",
                            data.agentRegistrationNumber)),
                    React.createElement("div", { className: "rpoppin__result__content rpoppin__result__content--".concat(isSuccess ? "green" : "red") },
                        React.createElement("div", { className: "rpoppin__result__image" }, isSuccess ? React.createElement(Success, { fill: "#55D2BE" }) : React.createElement(Error, null)),
                        React.createElement("p", null, message)))),
            React.createElement(Row, { className: "justify-content-center" },
                React.createElement(Col, { sm: 4 },
                    React.createElement(Button, { color: isSuccess ? "green" : "red", onClick: function () {
                            return document.dispatchEvent(new CustomEvent("onReactPoppinClose"));
                        }, tradKey: "ok", show: true }))))));
};
export default StepResult;
