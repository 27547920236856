import React from "react";
import i18n from "../../../../../libs/i18n/i18n";

const BlockInfos = ({}) => {
  return (
    <div className="blockInfos">
      <div className="blockInfos__title">
        {i18n.t("extranet.home.infoMyGeorgesTitle")}
      </div>
      <div className="blockInfos__description">
        {i18n.t("extranet.home.infoMyGeorgesContent")}
      </div>
    </div>
  );
};

export default BlockInfos;
