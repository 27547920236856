import React from 'react';
import TagList from './tagList';
var ClothContainerContent = function (_a) {
    var tags = _a.tags, code = _a.code;
    return (React.createElement(React.Fragment, null, Object.keys(tags).map(function (clothContainerCode) { return (React.createElement("div", { key: clothContainerCode, className: "container_list" },
        React.createElement("p", { className: "container_state" },
            React.createElement("span", null,
                "Housse ",
                tags[clothContainerCode][0].clothContainer.closed
                    ? React.createElement("span", { className: "text-red" }, "Ferm\u00E9e")
                    : React.createElement("span", { className: "text-green" }, "Ouverte")),
            React.createElement("span", null, tags[clothContainerCode][0].clothContainer.code)),
        React.createElement(TagList, { tags: tags[clothContainerCode], currentCode: code }))); })));
};
export default ClothContainerContent;
