import React from "react";
import Col from "../grid/col";
import IconMan from "../../../svg/iconMan";
var AgentCard = function (_a) {
    var agentName = _a.agentName, reverse = _a.reverse;
    return (React.createElement(Col, { sm: 3, className: "d-flex" },
        React.createElement("div", { className: "agentCard ".concat(reverse ? "agentCard--reverse" : "") },
            React.createElement("div", { className: "agentCard__info" }, agentName),
            React.createElement("div", { className: "agentCard__info" }, agentName ? React.createElement(IconMan, null) : false))));
};
export default AgentCard;
