import React, { useState } from "react";
import i18n from "../../libs/i18n/i18n";
export var InfoBoxType;
(function (InfoBoxType) {
    InfoBoxType["INFO"] = "info";
    InfoBoxType["WARNING"] = "warning";
})(InfoBoxType || (InfoBoxType = {}));
var SeeMore = function (_a) {
    var infoBox = _a.infoBox, active = _a.active;
    if (infoBox.type === "info") {
        return active
            ? React.createElement("span", { className: "infobox__show" }, "Masquer")
            : React.createElement("span", { className: "infobox__show" }, "Afficher");
    }
    return React.createElement(React.Fragment, null);
};
var InfoLine = function (_a) {
    var infoBox = _a.infoBox;
    if (infoBox.type === "info") {
        return (React.createElement(React.Fragment, null, Object.keys(infoBox.data).map(function (key) { return (React.createElement("div", { key: infoBox.data[key].code },
            React.createElement("p", null,
                "\u00C9tablissement ",
                infoBox.data[key].name,
                " ",
                infoBox.data[key].code),
            React.createElement("p", null,
                "- ",
                i18n.t(infoBox.data[key].messageIn),
                React.createElement("br", null),
                "- ",
                i18n.t(infoBox.data[key].messageOut)))); })));
    }
    return React.createElement(React.Fragment, null);
};
var InfoBox = function (_a) {
    var infoBox = _a.infoBox;
    var _b = useState(false), active = _b[0], setActive = _b[1];
    var handleClick = function () {
        if (infoBox.type !== "warning") {
            setActive(!active);
        }
    };
    if (infoBox && infoBox.message) {
        return (React.createElement("div", { className: "infobox infobox".concat(infoBox.type === "info" ? "--orange" : "--red"), onClick: function () { return handleClick(); } },
            React.createElement("div", { className: "infobox__head" },
                React.createElement("span", { className: "infobox__span" }, infoBox.type === "info" ? "Information" : "Attention"),
                React.createElement("div", { className: "infobox__text" },
                    i18n.t(infoBox.message),
                    React.createElement(SeeMore, { infoBox: infoBox, active: active }))),
            React.createElement("div", { className: "infobox__info-container ".concat(active ? "active" : "") },
                React.createElement(InfoLine, { infoBox: infoBox }))));
    }
    return React.createElement(React.Fragment, null);
};
export default InfoBox;
