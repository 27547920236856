import React from "react";
import i18n from "../../../../../libs/i18n/i18n";

const LinkFile = ({titleKey, format, fileType, link}) => {
  return (
    <div className="linkFile">
        <div className="linkFile__img"></div>
        <div className="linkFile__content"></div>
        <div className="linkFile__link"></div>
    </div>
  );
};

export default LinkFile;
