import React, { useContext, useEffect, useState } from 'react';
import useEventDisplay from '../../shared/hooks/useEventDisplay';
import '../../../../css/modules/askReform/base.scss';
import AskReformContent from './components/AskReformContent';
import AskReformProvider, { AskReformContext } from './context/AskReformProvider';
import { SET_TAG_DATA } from './context/AskReformReducer';
var AskReform = function () {
    var _a = useContext(AskReformContext), tagData = _a[0].tagData, dispatch = _a[1];
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    // script.js send the tag code after each scan.
    useEventDisplay('initAskReform', null, function (e) {
        dispatch({ type: SET_TAG_DATA, payload: e.detail.data });
    });
    // Open the reform block when user click on Reform button (out of React).
    var show = useEventDisplay('openReform', 'hideAskReform', undefined, function () {
        dispatch({ type: SET_TAG_DATA, payload: null });
        setIsOpen(false);
    });
    // Open on reform button click (out of React)
    useEffect(function () {
        setIsOpen(show);
    }, [show]);
    var getTitle = function () {
        if (tagData.waitingReformation) {
            return 'Réforme proposée';
        }
        else if (tagData.isReformed) {
            return 'Réformé';
        }
        else {
            return 'Proposer une réforme';
        }
    };
    if (!tagData) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "title ask-reform--title", onClick: function () { return setIsOpen(!isOpen); } },
            React.createElement("div", { className: "toggle icons" },
                React.createElement("i", { className: "caret icon ".concat(isOpen ? 'down' : 'right') })),
            getTitle()),
        tagData.id &&
            React.createElement(AskReformContent, { isOpen: isOpen })));
};
export default (function () { return (React.createElement(AskReformProvider, null,
    React.createElement(AskReform, null))); });
