import React, { useContext } from 'react';
import i18n from '../../../libs/i18n/i18n';
import useEventDisplay from '../../shared/hooks/useEventDisplay';
import TransferProvider, { TransferContext } from './context/TransferProvider';
import Modal from './components/Modal';
import TransferForm from './components/TransferForm';
import Results from './components/Results';
import { CLEAR_ALL } from './context/TransferReducer';
var TransferTags = function (props) {
    var _a = useContext(TransferContext), dispatch = _a[1];
    var show = useEventDisplay('displayTransferScreen', 'hideTransferScreen', undefined, function () { return dispatch({ type: CLEAR_ALL }); });
    if (show) {
        return (React.createElement("div", { className: "transfer-tags-page" },
            React.createElement("h1", null, i18n.t('transfer.title')),
            React.createElement(TransferForm, null),
            React.createElement(Results, null),
            React.createElement(Modal, null)));
    }
    return null;
};
export default (function () { return (React.createElement(TransferProvider, null,
    React.createElement(TransferTags, null))); });
