import React from 'react';
var AgentPlace = function (_a) {
    var agent = _a.agent;
    if (!agent) {
        return null;
    }
    return (React.createElement("div", null,
        agent.service ? "\n        ".concat(agent.service.infrastructure.client.name, "\n        ").concat(agent.service.infrastructure.client.code, " - \n        ").concat(agent.service.infrastructure.name, " - \n        ").concat(agent.service.name, " \n        ").concat(agent.service.code, "\n      ")
            : null,
        agent.deliveryPlace ? (" - ".concat(agent.deliveryPlace.name, " ").concat(agent.deliveryPlace.code)) : null,
        agent.deliveryPlace.washingCenter ? (" (".concat(agent.deliveryPlace.washingCenter.name, ")")) : null));
};
export default AgentPlace;
