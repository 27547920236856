import React from "react";

const MainBanner = ({userName, clientName, templateTitle, templateBody}) => {
  return (
    <div className="welcomeBanner">
      <h2 className="welcomeBanner__title">
        {templateTitle.replace("[USER_NAME]", userName)}
      </h2>
      <p className="welcomeBanner__title">
        {templateBody.replace("[CLIENT_NAME]", clientName)}
      </p>
    </div>
  );
};

export default MainBanner;
