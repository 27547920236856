import React, { useContext, useEffect, useState } from 'react';
import i18n from '../../../../libs/i18n/i18n';
import Autocomplete from '../../../shared/form/Autocomplete';
import { searchAllAgents } from '../../../../api/dotationTransfer';
import { TransferContext } from '../context/TransferProvider';
import { CLEAR_ALL, SET_AGENT } from '../context/TransferReducer';
import AgentPlace from './AgentPlace';
var getLabel = function (agent) {
    var label = "".concat(agent.firstname, " ").concat(agent.name, " ").concat(agent.registrationNumber);
    label += agent.deliveryPlace ? " - ".concat(agent.deliveryPlace.name, " ").concat(agent.deliveryPlace.code) : '';
    label += agent.deliveryPlace.washingCenter ? " (".concat(agent.deliveryPlace.washingCenter.name, ")") : '';
    label += agent.active ? '' : ' - (Désactivé)';
    return label;
};
var AgentBlock = function () {
    var _a = useContext(TransferContext), state = _a[0], dispatch = _a[1];
    var _b = useState(''), defaultValue = _b[0], setDefaultValue = _b[1];
    useEffect(function () {
        setDefaultValue(state.agent === null ? '' : getLabel(state.agent));
    }, [state.agent]);
    return (React.createElement("div", { className: "recipient-agent-container ui form" },
        React.createElement(Autocomplete, { placeholder: i18n.t('transfer.agent.placeholder'), defaultValue: defaultValue, onChange: function (value) { return searchAllAgents(value); }, label: getLabel, onSelect: function (agent) { return dispatch({ type: SET_AGENT, payload: agent }); }, disabled: state.tags.length > 0, onClear: function () { return dispatch({ type: CLEAR_ALL }); } }),
        React.createElement("div", { className: "agent-informations" }, state.agent !== null && (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "agent-informations--name" },
                state.agent.firstname,
                " ",
                state.agent.name),
            React.createElement("p", { className: "agent-informations--code" }, state.agent.registrationNumber),
            React.createElement("div", { className: "agent-informations--place" },
                React.createElement("i", { className: "icon location arrow" }),
                React.createElement(AgentPlace, { agent: state.agent })))))));
};
export default AgentBlock;
