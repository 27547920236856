import React, {useState, useEffect} from "react";

import Container from "../../../grid/container";
import Row from "../../../grid/row";
import Col from "../../../grid/col";
import AgentSelection from "../../../shared/form/agentSelection";
import Button from "../../../shared/button";
import InputCheckbox from "../../../shared/form/inputCheckbox";
import InputDate from "../../../shared/form/inputDate";
import TransferScheme from "../../../shared/transferScheme";

import i18n from "../../../../libs/i18n/i18n";

const StepMain = ({
  setCurrentStep,
  data,
  selectedAgentId,
  setSelectedAgentId,
  setTransferDotation,
  transferDotation,
  date,
  setDate,
  userId,
  agent,
  setAgent,
}) => {
  const [acceptTerms, setAcceptTerms] = useState(false);

  return (
    <Container className="rform">
      <Row className="rform__row">
        <Col sm={3}>{i18n.t("agentToDesactivate")}:</Col>
        <Col sm={7} column={true}>
          <span className="rform__info rform__info--bold">
            {data.firstname} {data.name} - {data.registrationNumber}
          </span>
          <span className="rform__info">
            <p>
              {data.deliveryPlace} <br />
              Dotation: {data.tagsNumber} vêtements
              <br />

              {"Armoire: "}{data.wardrobe && data.wardrobe.toString().length < 2 ? '0' + data.wardrobe : data.wardrobe} {" - "}
              {"Casier: "} {data.locker && data.locker.toString().length < 2 ? '0' + data.locker : data.locker}
            </p>
          </span>
        </Col>
      </Row>

      <Row className="rform__row">
        <Col sm={3} className={"d-flex align-items-center"}>
          Date de fin d'activité:
        </Col>
        <Col sm={9}>
          <InputDate date={date} setDate={setDate} />
        </Col>
      </Row>

      {data.tagsNumber ? (
        <>
          <Row className="rform__row">
            <Col sm={3}>{i18n.t("dotationTransfert")}:</Col>
            <Col sm={9}>
              <InputCheckbox
                label={"transferDotationsWithAgent"}
                checkedAction={checked => setTransferDotation(checked)}
              />
            </Col>
          </Row>

          <AgentSelection
            setSelectedAgentId={setSelectedAgentId}
            selectedAgentId={selectedAgentId}
            agentId={data.id}
            setAgent={setAgent}
            display={transferDotation}
          />
        </>
      ) : (
        false
      )}

      <TransferScheme
        originalAgent={`${data.firstname} ${data.name} ${data.registrationNumber}`}
        recipientAgent={`${agent.firstname} ${agent.name} ${agent.registrationNumber}`}
        transferDotation={transferDotation}
      />

      <Row className="rform__row">
        <Col sm={12}>
          <InputCheckbox
            label={"understandDesactivation"}
            checkedAction={checked => setAcceptTerms(checked)}
          />
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col sm={5}>
          <Button
            color={"red"}
            tradKey={"cancel"}
            onClick={() => document.dispatchEvent(new CustomEvent("onReactPoppinClose"))}
            show={true}
          />
        </Col>
        <Col sm={5}>
          <Button
            color={"green"}
            tradKey={"validate"}
            onClick={() => setCurrentStep("loading")}
            show={acceptTerms && date}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default StepMain;
