var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { getDeliveryListing } from '../../../api/deliveryListing';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import french from 'dayjs/locale/fr';
import Spinner from '../../shared/spinner';
import TagList from './components/tagList';
import ClothContainerContent from './components/ClothContainerContent';
dayjs.extend(weekOfYear);
dayjs.locale(french);
var INFRASTRUCTURE_TYPE;
(function (INFRASTRUCTURE_TYPE) {
    INFRASTRUCTURE_TYPE[INFRASTRUCTURE_TYPE["INDUSTRY"] = 1] = "INDUSTRY";
    INFRASTRUCTURE_TYPE[INFRASTRUCTURE_TYPE["IMAGE"] = 2] = "IMAGE";
})(INFRASTRUCTURE_TYPE || (INFRASTRUCTURE_TYPE = {}));
/**
 * Format tags inside container, closed or opened.
 *
 * @param tags
 * @param containerIsClosed
 */
var formatTagsInContainer = function (tags, containerIsClosed) {
    return tags
        .filter(function (tag) { return tag.clothContainer !== null && tag.clothContainer.closed === containerIsClosed; })
        .reduce(function (acc, tag) {
        if (acc[tag.clothContainer.code]) {
            acc[tag.clothContainer.code].push(tag);
        }
        else {
            acc[tag.clothContainer.code] = [tag];
        }
        return acc;
    }, {});
};
/**
 * Display other tags for current agent and current delivery.
 */
var DeliveryListing = function () {
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(null), code = _b[0], setCode = _b[1];
    var _c = useState([]), tagsToPutInContainers = _c[0], setTagsToPutInContainers = _c[1];
    var _d = useState({}), tagsInClosedContainers = _d[0], setTagsInClosedContainers = _d[1];
    var _e = useState({}), tagsInOpenedContainers = _e[0], setTagsInOpenedContainers = _e[1];
    var _f = useState(null), delivery = _f[0], setDelivery = _f[1];
    var _g = useState(null), deliveryDate = _g[0], setDeliveryDate = _g[1];
    /**
     * Listen when tag is requested.
     */
    useEffect(function () {
        document.addEventListener('onTagRequested', function (e) { return onTagRequested(e.detail.tagCode, e.detail.infrastructureType); });
        return function () { return document.removeEventListener('onTagRequested', function (e) { return onTagRequested(e.detail.tagCode, e.detail.infrastructureType); }); };
    }, []);
    /**
     * On tag requested event
     * @param tagCode
     * @param infrastructureType
     */
    var onTagRequested = function (tagCode, infrastructureType) {
        // This block is only for AirFrance
        if (infrastructureType === INFRASTRUCTURE_TYPE.IMAGE) {
            setIsLoading(true);
            fetchDeliveryListing(tagCode);
            setCode(tagCode);
        }
        else {
            clear();
        }
    };
    /**
     * Clear data
     */
    var clear = function () {
        setDelivery(null);
        setIsLoading(false);
    };
    /**
     * Get delivery listing and sort data.
     */
    var fetchDeliveryListing = function (code) { return __awaiter(void 0, void 0, void 0, function () {
        var response, deliveryMysqlDate, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getDeliveryListing(code)];
                case 1:
                    response = _a.sent();
                    if (!response.delivery) {
                        return [2 /*return*/, clear()];
                    }
                    deliveryMysqlDate = response.delivery.deliveryDate;
                    setDelivery(response.delivery);
                    setDeliveryDate(dayjs(deliveryMysqlDate));
                    setTagsToPutInContainers(response.containers.filter(function (container) { return container.clothContainer === null; }));
                    setTagsInOpenedContainers(formatTagsInContainer(response.containers, false));
                    setTagsInClosedContainers(formatTagsInContainer(response.containers, true));
                    setIsLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    clear();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    if (isLoading) {
        return React.createElement(Spinner, { classes: "same_date_spinner" });
    }
    if (delivery) {
        return (React.createElement("div", { className: "delivery_listing" },
            React.createElement("p", { className: "delivery_code" },
                "Livraison ",
                delivery.code),
            React.createElement("div", null,
                React.createElement("div", null,
                    deliveryDate && (React.createElement(React.Fragment, null,
                        "Pour le ",
                        React.createElement("strong", null,
                            React.createElement("span", { className: "delivery-week" },
                                "S",
                                deliveryDate.week()),
                            " ",
                            deliveryDate.format('dddd DD/MM/YYYY'),
                            " "))),
                    dayjs(delivery.deliveryPartDay.startTime).hour() < 12 ? ' Matin' : ' Après-midi'),
                tagsToPutInContainers.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "tag_state" }, "Restants"),
                    React.createElement(TagList, { tags: tagsToPutInContainers, currentCode: code }))),
                (Object.keys(tagsInOpenedContainers).length > 0 || Object.keys(tagsInClosedContainers).length > 0) && (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "tag_state" }, "D\u00E9j\u00E0 pr\u00EAts"),
                    React.createElement(ClothContainerContent, { tags: tagsInOpenedContainers, code: code }),
                    React.createElement(ClothContainerContent, { tags: tagsInClosedContainers, code: code }))))));
    }
    return null;
};
export default (function () { return React.createElement(DeliveryListing, null); });
