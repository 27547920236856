var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useState } from 'react';
import useEventDisplay from '../../shared/hooks/useEventDisplay';
import '../../../../css/modules/askReform/base.scss';
import { getRecyclingBags, addRecycling } from '../../../api/recycling';
import AskRecyclingProvider, { AskRecyclingContext } from './context/AskRecyclingProvider';
import { RECYCLING_ASKED, SET_TAG_DATA } from './context/AskRecyclingReducer';
import Spinner from '../../shared/spinner';
import { getWashingBag } from "../../../api/washingBag";
var AskRecycling = function () {
    var _a = useContext(AskRecyclingContext), tagData = _a[0].tagData, dispatch = _a[1];
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useState([]), bags = _c[0], setBags = _c[1];
    var _d = useState(false), hasLoaded = _d[0], setHasLoaded = _d[1];
    var _e = useState(null), error = _e[0], setError = _e[1];
    var _f = useState(false), isAskingRecycling = _f[0], setIsAskingRecycling = _f[1];
    var _g = useState(0), selectedBagId = _g[0], setSelectedBagId = _g[1];
    // script.js send the tag code after each scan.
    useEventDisplay('initAskRecycling', null, function (e) {
        dispatch({ type: SET_TAG_DATA, payload: e.detail.data });
    });
    // Open the reform block when user click on Reform button (out of React).
    var show = useEventDisplay('openRecycling', 'hideAskRecycling', undefined, function () {
        dispatch({ type: SET_TAG_DATA, payload: null });
        setIsOpen(false);
    });
    var _loadBags = function () { return __awaiter(void 0, void 0, void 0, function () {
        var contentId, type;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!tagData) return [3 /*break*/, 2];
                    contentId = tagData.type == 'washing_bag' ? tagData.content.id : tagData.id;
                    type = tagData.type == 'washing_bag' ? 'washing_bag' : 'tag';
                    return [4 /*yield*/, getRecyclingBags(contentId, type)
                            .then(function (response) {
                            setBags(response.response);
                        })
                            .catch(function (error) { return console.error('An error occurred while fetching reforms reasons.', error); })
                            .finally(function () { return setHasLoaded(true); })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var contentId, type, data, washingBag, e_1, scanInput;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 5, 6, 7]);
                    setIsAskingRecycling(true);
                    contentId = tagData.type == 'washing_bag' ? tagData.content.id : tagData.id;
                    type = tagData.type == 'washing_bag' ? 'washing_bag' : 'tag';
                    return [4 /*yield*/, addRecycling(contentId, type, selectedBagId)];
                case 1:
                    data = _a.sent();
                    if (!data.success) {
                        throw new Error("Une erreur est survenue : ".concat(data.response));
                    }
                    if (!(type == 'tag')) return [3 /*break*/, 2];
                    document.dispatchEvent(new CustomEvent('askRecyclingDone', {
                        detail: { data: data.response }
                    }));
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, getWashingBag(data.response.code)];
                case 3:
                    washingBag = _a.sent();
                    document.dispatchEvent(new CustomEvent('askRecyclingWashingBagDone', {
                        detail: { data: washingBag }
                    }));
                    _a.label = 4;
                case 4:
                    dispatch({ type: RECYCLING_ASKED });
                    setError(null);
                    return [3 /*break*/, 7];
                case 5:
                    e_1 = _a.sent();
                    setError(e_1.message);
                    return [3 /*break*/, 7];
                case 6:
                    setIsAskingRecycling(false);
                    scanInput = document.getElementById('scan-input');
                    scanInput.value = '';
                    scanInput.focus();
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    // Open on reform button click (out of React)
    useEffect(function () {
        setIsOpen(show);
        _loadBags();
    }, [show]);
    var getTitle = function () {
        return 'Recylage';
    };
    if (!tagData) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "title ask-reform--title", onClick: function () { return setIsOpen(!isOpen); } },
            React.createElement("div", { className: "toggle icons" },
                React.createElement("i", { className: "caret icon ".concat(isOpen ? 'down' : 'right') })),
            getTitle()),
        tagData.id && isOpen &&
            React.createElement("div", { className: "ask-recycling--content" }, !hasLoaded
                ? React.createElement(Spinner, { classes: "ask-recycling--spinner" })
                : (React.createElement(React.Fragment, null,
                    React.createElement("h4", null, "Choix du bac de recyclage"),
                    React.createElement("select", { onChange: function (e) {
                            setSelectedBagId(parseInt(e.target.value));
                            console.log('e', e);
                        }, style: {
                            width: '300px',
                            display: 'block'
                        } },
                        React.createElement("option", null, "Choisissez un bac de recyclage"),
                        bags && bags.map(function (bag) {
                            var bagTitle = bag.code + ' : ';
                            if (bag.client) {
                                bagTitle += bag.client.code + ' - ' + bag.client.name + ' / ';
                            }
                            if (bag.infrastructure) {
                                bagTitle += bag.infrastructure.code + ' - ' + bag.infrastructure.name;
                            }
                            return React.createElement("option", { value: bag.id }, bagTitle);
                        })),
                    React.createElement("button", { id: "ask-recycling--form-submit", className: "ui black tiny button ".concat(isAskingRecycling && 'loading'), onClick: handleSubmit }, "Ajouter au bac"),
                    error && React.createElement("div", { className: "red" }, error))))));
};
export default (function () { return (React.createElement(AskRecyclingProvider, null,
    React.createElement(AskRecycling, null))); });
