import React, { useRef } from "react";
// @ts-ignore
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Container from "./../../../components/grid/container";
import Row from "./../../../components/grid/row";
import Col from "./../../../components/grid/col";
import i18n from "../../../libs/i18n/i18n";
var ModalWrapper = function (_a) {
    var children = _a.children, currentStep = _a.currentStep, modalTitle = _a.modalTitle;
    var contentContainer = useRef(null);
    var poppin = useRef(null);
    return (React.createElement("div", { className: "rpoppin", style: { height: "auto" }, ref: poppin },
        React.createElement(Container, { className: "" },
            React.createElement(Row, { className: null },
                React.createElement(Col, { sm: 12 },
                    React.createElement("h2", { className: "rpoppin__title" }, i18n.t(modalTitle)))),
            React.createElement("div", { ref: contentContainer },
                React.createElement(TransitionGroup, null,
                    React.createElement(CSSTransition, { key: currentStep, classNames: "fadeTranslate", timeout: 500, mountOnEnter: true, unmountOnExit: true }, children))))));
};
export default ModalWrapper;
