import React, {useState, useEffect} from "react";

import InputDropdown from "../../shared/form/inputDropdown";
import Row from "../../grid/row";
import Col from "../../grid/col";

import {
  getAllInfrastructures,
  getAllServices,
  getAllDeliveryPlaces,
} from "../../../api/agentMutation";
import { checkTransferProfession, checkTransferRules } from "../../../api/dotationTransfer";

const PlacesSelection = ({
  data,
  userId,
  clients,
  clientSelected,
  setClientSelected,
  infrastructureSelected,
  setInfrastructureSelected,
  serviceSelected,
  setServiceSelected,
  setDeliveryPlaceSelected,
  setInfoBox,
  setInfoBoxProfession,
  multiple,
}) => {
  const [infrastructures, setInfrastructures] = useState([]);
  const [services, setServices] = useState([]);
  const [deliveryPlaces, setDeliveryPlaces] = useState([]);

  const agentId = multiple == true ? agentId : data.id;

  useEffect(() => {
    if (clientSelected) {
      const getInfras = async () => {
        try {
          const response = await getAllInfrastructures(
            clientSelected,
            userId ? userId : data.userId
          );

          setInfrastructures(response.results);
        } catch (e) {
          console.log("Erreur lors de la récupération des infrastructures", e);
        }
      };

      getInfras();
    }
  }, [clientSelected]);

  useEffect(() => {
    if (infrastructureSelected) {
      const getServices = async () => {
        try {
          const response = await getAllServices(
            infrastructureSelected,
            userId ? userId : data.userId
          );

          setServices(response.results);
        } catch (e) {
          console.log("Erreur lors de la récupération des infrastructures", e);
        }
      };

      const checkProfession = async () => {
        const infos = await checkTransferProfession(agentId, infrastructureSelected);
        setInfoBoxProfession(infos);
      };

      checkProfession();
      getServices();
    }
  }, [infrastructureSelected]);

  useEffect(() => {
    if (serviceSelected) {
      const getDeliveryPlaces = async () => {
        try {
          const response = await getAllDeliveryPlaces(
            serviceSelected,
            userId ? userId : data.userId
          );

          setDeliveryPlaces(response.results);
        } catch (e) {
          console.log("Erreur lors de la récupération des infrastructures", e);
        }
      };

      const checkRules = async () => {
        const transferInfos = await checkTransferRules(agentId, false, serviceSelected);

        setInfoBox(transferInfos);
      };

      checkRules();
      getDeliveryPlaces();
    }
  }, [serviceSelected]);

  return (
    <Row>
      <Col sm={6}>
        <InputDropdown
          results={clients}
          autoOpenOnResult={false}
          selectedAction={value => setClientSelected(value)}
          placeholder="client"
        />
      </Col>
      <Col sm={6}>
        <InputDropdown
          results={infrastructures}
          autoOpenOnResult={false}
          selectedAction={value => setInfrastructureSelected(value)}
          placeholder="infrastructure"
        />
      </Col>
      <Col sm={6}>
        <InputDropdown
          results={services}
          autoOpenOnResult={false}
          selectedAction={value => setServiceSelected(value)}
          placeholder="service"
        />
      </Col>
      <Col sm={6}>
        <InputDropdown
          results={deliveryPlaces}
          autoOpenOnResult={false}
          selectedAction={value => setDeliveryPlaceSelected(value)}
          placeholder="deliveryPlace"
        />
      </Col>
    </Row>
  );
};

export default PlacesSelection;
