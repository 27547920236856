import {call} from "./apiCall";

/**
 * Get all clients
 */
export const getAllClients = async userId => {
  const response = await call(
    `/v1/admin/client/get_clients_filtered_by_user/${userId}`,
    "POST"
  );

  return response;
};

/**
 * Get all infrastructures
 */
export const getAllInfrastructures = async (clientId, userId) => {
  const response = await call(
    `/v1/admin/infrastructure/${clientId}/user/${userId}`,
    "POST"
  );

  return response;
};

/**
 * Get all services
 */
export const getAllServices = async (infrastructureId, userId) => {
  const response = await call(
    `/v1/admin/service/${infrastructureId}/user/${userId}`,
    "POST"
  );

  return response;
};

/**
 * Get all delivery points
 */
export const getAllDeliveryPlaces = async (serviceId, userId) => {
  const response = await call(
    `/v1/admin/deliveryplace/${serviceId}/user/${userId}`,
    "POST"
  );

  return response;
};

/**
 * Get agent dotations
 */
export const getAgentDotations = async agentId => {
  const response = await call(`/v1/admin/agent/dotations/${agentId}`, "POST");

  return response;
};

/**
 * Set Agent DeliveryPlace Association
 * @param {int} agentId
 * @param {int} deliveryPlaceId
 * @param {int} serviceId
 */
export const setAgentDeliveryPlaceAssociation = async (
  agentId,
  deliveryPlaceId,
  serviceId,
  wardrobe,
  locker,
  lockerType
) => {
  const response = await call(
    `/v1/admin/agent/${agentId}/deliveryplace/${deliveryPlaceId}/service/${serviceId}`,
    "POST",
    `wardrobe=${wardrobe}&locker=${locker}&lockerType=${lockerType}`
  );

  return response;
};
